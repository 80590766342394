import React from 'react';
import Restrictions from '../../components/restrictions/Restrictions';
import MandatoryConditions from './MandatoryConditions';
import DealingRules from './DealingRules';
import AccountingRules from './AccountingRules';

export default function Rules(props) {
  const { conditionsData, restrictionsData } = props;

  return (
    <>
      <Restrictions restrictions={restrictionsData} component="rules" />
      <AccountingRules />
      <DealingRules conditionsData={conditionsData} />
      <MandatoryConditions conditionsData={conditionsData.conditions} />
    </>
  );
}
