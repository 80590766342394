/* Library */
import React, { useContext } from 'react';
import moment from 'moment';

/* Styles */
import './riverDataSummaryOverview.scss';

/* Component  */
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';

/* Assests */
import MapIcon from '../dataSummary/MapIcon';

/* Context */
import { MapContext } from '../../contexts/MapContext';
import { MobileContext } from '../../contexts/MobileContext';

/* Helpers */
import { formatNumberDecimals } from '../../helpers/Utils';

/* Constants */
import mapFilterConstant from '../../constants/MapFilterConstants';
import hydroDataConstant from '../../constants/HydrometricsConstants';
const { GAUGE, DAM, WEIR, BORE } = mapFilterConstant.MAP_POPUP_ITEMS;
const component = 'river-data-summary';

const RiverDataSummaryOverview = ({
  selected,
  station,
  setStationId,
  stationType,
  detailsLoading,
}) => {
  const IS_BORE = stationType === BORE.name;
  const { isMobile } = useContext(MobileContext);
  const { toggleMarker } = useContext(MapContext);
  const dateFormat = IS_BORE ? 'MMM YY' : 'HH:mm DD MMM YY';
  const stationName = IS_BORE ? station.bore_name : station.station_display_name;

  const latestData = (type, division = 1) => {
    const decimals = hydroDataConstant.DATA_LABEL_FORMAT[type].decimals;
    return IS_BORE || station.hydrometric_types?.includes(type) ? (
      <DataQualityText qualityCode={station[type]?.qualityCode}>
        {station[type].value
          ? formatNumberDecimals(station[type].value / division, false, false, decimals)
          : '-'}
      </DataQualityText>
    ) : (
      '-'
    );
  };

  return (
    <>
      <div className={`${component}-overview-container`}>
        <div
          className={`${component}-overview-container-station-id${
            selected ? '-selected' : ''
          } priority-1`}
        >
          {station.station_id}
        </div>
        <div
          className={`${component}-overview-container-station-name${
            selected ? '-selected' : ''
          } priority-2`}
        >
          <span>{isMobile ? stationName : station.station_name}</span>
        </div>
        <div className={`${component}-overview-container-icon priority-3`}>
          <MapIcon
            onClick={event => {
              setStationId('');
              event.stopPropagation();
              window.scrollTo(0, 0);
              toggleMarker(station, station.station_type === WEIR.name ? DAM.name : GAUGE.name);
            }}
          />
        </div>
        <div
          className={`${
            detailsLoading ? 'skeleton' : ''
          } ${component}-overview-container-flow_rate priority-4`}
        >
          {!detailsLoading &&
            (stationType === WEIR.name &&
            typeof station.volume?.value !== 'undefined' &&
            station.active
              ? latestData('volume', 1000)
              : typeof station.flow_rate?.value !== 'undefined' && station.active
              ? latestData('flow_rate', 1)
              : stationType === BORE.name && typeof station.water_level?.value !== 'undefined'
              ? latestData('water_level', 2)
              : '-')}
        </div>
        {stationType !== BORE.name && (
          <div
            className={`${detailsLoading ? 'skeleton' : ''} ${component}-overview-container-level${
              detailsLoading ? '-loading' : ''
            } priority-5`}
          >
            {!detailsLoading &&
              (typeof station.level?.value !== 'undefined' && station.active
                ? latestData('level', 1)
                : '-')}
          </div>
        )}
        {station.station_type === GAUGE.name && (
          <div
            className={`${
              detailsLoading ? 'skeleton' : ''
            } ${component}-overview-container-level priority-6`}
          >
            {!detailsLoading &&
              (typeof station['dissolved_oxygen']?.value !== 'undefined' && station.active
                ? latestData('dissolved_oxygen', 1)
                : '-')}
          </div>
        )}
        <div
          className={`${
            detailsLoading ? 'skeleton' : ''
          } ${component}-overview-container-date priority-7`}
        >
          {!detailsLoading &&
            (station.date && Boolean(station.active) === true
              ? moment(station.date, 'DD-MMM-YYYY HH:mm').format(dateFormat)
              : '-')}
        </div>
        {stationType === 'gauge' && (
          <div className={`priority-8`}>
            {station.has_forecast &&
            (station.has_forecast.bom || station.has_forecast.carm) &&
            station.active
              ? 'Available'
              : ''}
          </div>
        )}
      </div>
      {station.commentary && (
        <div className={`${component}-overview-commentary`}>{station.commentary}</div>
      )}
    </>
  );
};

export default RiverDataSummaryOverview;
