// Library
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNumber } from 'lodash';
import moment from 'moment';
import {
  Tooltip,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Line,
  LineChart,
  Bar,
  BarChart,
  Label,
  Legend,
} from 'recharts';
// Style
import './dataSummaryGraph.scss';

// Helper
import { formatNumberDecimals } from '../../helpers/Utils';

// Component
import ToggleButton from './ToggleButton';
import DownloadLink from '../chartProperties/ChartCSVDownloadLink';
import DownloadPageLink from '../chartProperties/ChartToDownloadPageLink';
import AlertLink from '../alert/AlertLink';
import GeneralDropdown from '../dropDown/GeneralDropdown';

// Context
import { MobileContext } from '../../contexts/MobileContext';
import AlertResponse from '../alert/AlertResponse';
import GaugeAlertModalBody from '../alert/GaugeAlertModalBody';

// Constant
import alertResponseText from '../../components/alert/AlertResponseText.json';
import DataQualityConstants, { STATUS } from '../../constants/DataQualityConstant';
import config from '../../configs/featureToggleConfig.json';
const toShowQC = config['quality_code'].active;
const container = 'data-summary-graph';

const DataSummaryGraph = ({
  chartTitle,
  chartContent,
  qualities = {},
  toggle = false,
  dropDown = false,
  hydroOptions,
  toggleOptions,
  onHydroSelect,
  onToggleClick,
  activeOption,
  graphType,
  YAxisName,
  toolTipFormatter = value => `${formatNumberDecimals(value)}`,
  dateFormat = 'MMM YYYY',
  labelFormatter = name => moment(name, 'YYYY-MM-DD').format(dateFormat),
  yAxisTickFormatter = value => `${formatNumberDecimals(value)}`,
  yAxisTickReverse = false,
  chartHeight = 250,
  yAxisMinMaxDomain = [0, 'auto'],
  xAxisTicks = [],
  formatTick,
  stacked = false,
  stackKeys = [],
  lineDot = true,
  dataStation = '',
  allowDownload = false,
  allowHeavyDownload = false,
  allowAlert = false,
  tooltipCustomContent = null,
  marginBottom = 30,
  dataType,
  stationInfo,
  hasDataQuality,
  overlay = false,
}) => {
  const { isMobile } = useContext(MobileContext);
  const [showManageAlertResponse, setShowManageAlertResponse] = useState(false);
  const [responseType, setResponseType] = useState();

  let hideConfig = {};
  if (!isEmpty(stackKeys)) {
    stackKeys.map(key => {
      hideConfig[key.name] = false;
    });
  }
  const [hideBar, setHideBar] = useState(hideConfig);

  const sharedGraphSetting = {
    width: 800,
    margin: {
      top: 10,
      right: 15,
      bottom: marginBottom,
      left: YAxisName.length < 25 ? 30 : 60,
    },
    data: !isEmpty(chartContent) ? chartContent : [],
    type: 'monotone',
    color: '#0054a6',
    dataKey: 'value',
    forecastDataKey: 'forecastValue',
  };

  const onLegendClick = value => {
    const newOpacity = hideBar[value.dataKey] === false ? true : false;
    setHideBar({ ...hideBar, [value.dataKey]: newOpacity });
  };

  const sharedXAxis = (stackKey = 0) => {
    return (
      <XAxis
        dataKey="date"
        tickFormatter={
          formatTick
            ? formatTick
            : v =>
                moment(v).isValid() && !isNumber(v)
                  ? moment(v, 'YYYY-MM-DD').format(dateFormat)
                  : ''
        }
        tick={{ fill: 'black' }}
        dy={10}
        {...(!isEmpty(xAxisTicks) ? { ticks: xAxisTicks } : {})}
        minTickGap={formatTick ? 45 : 5}
        xAxisId={stackKey}
        hide={stackKey > 0}
        includeHidden
      />
    );
  };

  const sharedYAxis = () => {
    return (
      <YAxis
        tick={{ fill: 'black' }}
        tickFormatter={yAxisTickFormatter}
        interval={1}
        domain={yAxisMinMaxDomain}
        reversed={yAxisTickReverse}
      >
        {YAxisName.length < 25 ? (
          <Label value={YAxisName} angle={-90} dx={-50} position="center" />
        ) : YAxisName.includes('@') ? (
          <>
            <Label value={YAxisName.split('@')[0]} angle={-90} dx={-80} position="center" />
            <Label value={`@ ${YAxisName.split('@')[1]}`} angle={-90} dx={-60} />
          </>
        ) : (
          <>
            <Label
              value={YAxisName.slice(0, YAxisName.indexOf('(')).trim()}
              angle={-90}
              dx={-50}
              position="center"
            />
            <Label
              value={`(${YAxisName.slice(YAxisName.indexOf('(') + 1, -1).trim()})`}
              angle={-90}
              dx={-30}
            />
          </>
        )}
      </YAxis>
    );
  };

  const sharedToolTip = () => {
    return tooltipCustomContent ? (
      <Tooltip
        labelFormatter={labelFormatter}
        formatter={toolTipFormatter}
        content={tooltipCustomContent}
      />
    ) : (
      <Tooltip labelFormatter={labelFormatter} formatter={toolTipFormatter} />
    );
  };

  const lineChart = () => {
    const CustomizedDot = props => {
      const { cx, cy, payload } = props;
      const { qualityCode } = payload;

      if (qualityCode) {
        const qualityObj = DataQualityConstants[qualityCode] || {
          ...STATUS.NORMAL,
        };

        if (qualityObj?.statusName === STATUS.ANOMALY.statusName) {
          return <circle cx={cx} cy={cy} r="2" fill={'#f21d1d'} />;
        }
      }
      return payload.value && lineDot ? <circle cx={cx} cy={cy} r="1" fill={'#0054a6'} /> : <></>;
    };
    return (
      <LineChart
        width={sharedGraphSetting.width}
        data={sharedGraphSetting.data}
        margin={sharedGraphSetting.margin}
      >
        {sharedXAxis()}
        {sharedYAxis()}
        {sharedToolTip()}
        <Line
          dataKey={sharedGraphSetting.dataKey}
          strokeWidth={2}
          stroke={sharedGraphSetting.color}
          name={YAxisName}
          dot={
            hasDataQuality && toShowQC ? (
              <CustomizedDot />
            ) : lineDot ? (
              { strokeWidth: 0, r: 1, strokeDasharray: '', fill: sharedGraphSetting.color }
            ) : (
              false
            )
          }
        />
        <Line
          dataKey={sharedGraphSetting.forecastDataKey}
          connectNulls
          strokeWidth={2}
          stroke={sharedGraphSetting.color}
          name={`${YAxisName} - forecast`}
          // dot={{ strokeWidth: 0, r: 3, strokeDasharray: '', fill: sharedGraphSetting.color }}
          dot={false}
          strokeDasharray="5 5"
        />
      </LineChart>
    );
  };

  const sharedBar = (dataKey, stackId) => {
    return (
      <Bar
        key={dataKey.name}
        type={sharedGraphSetting.type}
        dataKey={dataKey.name}
        fill={dataKey.color}
        name={dataKey.name}
        xAxisId={dataKey.axisId}
        stackId={overlay ? dataKey.axisId : stackId}
        hide={hideBar[dataKey.name]}
      />
    );
  };

  const barChart = () => {
    const showLegend = stacked || overlay;
    return (
      <BarChart
        width={sharedGraphSetting.width}
        data={sharedGraphSetting.data}
        margin={sharedGraphSetting.margin}
      >
        {overlay ? stackKeys.map(stackKey => sharedXAxis(stackKey.axisId)) : sharedXAxis()}
        {sharedYAxis()}
        {sharedToolTip()}
        {showLegend ? stackKeys.map(stackKey => sharedBar(stackKey, 'stackIt')) : sharedBar()}
        {showLegend && (
          <Legend
            verticalAlign="bottom"
            align="left"
            onClick={overlay ? value => onLegendClick(value) : () => {}}
          />
        )}
      </BarChart>
    );
  };

  const renderManageAlertResponse = responseType => {
    setShowManageAlertResponse(true);
    setResponseType(responseType);
  };

  // only appear when on desktop
  return (
    <div>
      {!isMobile && (
        <div className={`${container}`}>
          <div className={`${container}-links`}>
            {allowDownload && (
              <DownloadLink
                data={sharedGraphSetting.data}
                filename={`${dataStation}${
                  activeOption.displayName
                    ? `_${activeOption.displayName.toLowerCase().replaceAll(' ', '_')}`
                    : ''
                }`}
                stationType={dataType}
                dataType={activeOption.hydroName ? activeOption.hydroName : activeOption}
                additionalData={qualities}
              />
            )}
            {allowHeavyDownload && (
              <DownloadPageLink
                hydrometric={activeOption?.waterDataKey || activeOption}
                siteId={dataStation}
                siteType={dataType}
                dataType="autoqc"
              />
            )}
            {allowAlert && (
              <AlertLink
                modalType={'gauge-modal'}
                showManageAlertResponse={showManageAlertResponse}
                setShowManageAlertResponse={setShowManageAlertResponse}
                alertModal={
                  <>
                    <AlertResponse
                      show={showManageAlertResponse}
                      responseBodyText={alertResponseText.update[responseType]}
                    />
                    {!showManageAlertResponse && (
                      <GaugeAlertModalBody
                        setManageAlertResponse={renderManageAlertResponse}
                        stationInfo={stationInfo}
                        hydroOptions={hydroOptions}
                        hydrometricInit={activeOption?.hydroName}
                      />
                    )}
                  </>
                }
              />
            )}
          </div>
          <div className={`${container}-header`}>
            <div className={`${container}-left`}>{chartTitle}</div>
            {toggle && !isEmpty(toggleOptions) && (
              <ToggleButton
                options={toggleOptions}
                onClick={e => onToggleClick(e)}
                activeDefault={activeOption}
              />
            )}
            {dropDown && !isEmpty(hydroOptions) && (
              <div className={`${container}-right`}>
                <GeneralDropdown
                  menuItem={hydroOptions.map(item => {
                    return { id: item.id, name: item.displayName };
                  })}
                  onItemClick={e => onHydroSelect(e.id)}
                  selectedItem={{ id: activeOption?.id, name: activeOption?.displayName }}
                  menuStyle={'general-dropdown-menu general-dropdown-menu-medium'}
                />
              </div>
            )}
          </div>
          <ResponsiveContainer width="99%" height={chartHeight}>
            {graphType === 'bar' ? barChart() : lineChart()}
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
};

export default DataSummaryGraph;

DataSummaryGraph.propTypes = {
  chartTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.objectOf(PropTypes.any)]),
  chartContent: PropTypes.array,
  YAxisName: PropTypes.string,
  toggle: PropTypes.bool,
  hydroOptions: PropTypes.array,
  toggleOptions: PropTypes.array,
  onHydroSelect: PropTypes.func,
  activeOption: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    hydroName: PropTypes.string,
  }),
  graphType: PropTypes.string,
  toolTipFormatter: PropTypes.func,
  labelFormatter: PropTypes.func,
  yAxisTickFormatter: PropTypes.func,
  dateFormat: PropTypes.string,
  yAxisTickReverse: PropTypes.bool,
  chartHeight: PropTypes.number,
  yAxisMinMaxDomain: PropTypes.arrayOf(PropTypes.any),
  xAxisTicks: PropTypes.array,
  stacked: PropTypes.bool,
  stackKeys: PropTypes.array,
  lineDot: PropTypes.bool,
  dataStation: PropTypes.string,
  allowDownload: PropTypes.bool,
  allowAlert: PropTypes.bool,
  tooltipCustomContent: PropTypes.element,
  marginBottom: PropTypes.number,
  dataType: PropTypes.string,
  stationInfo: PropTypes.object,
  allowHeavyDownload: PropTypes.bool,
};
