const WaterSourceConstant = require('./WaterSourceConstants');
const config = require('../configs/config');
const facebookIcon = require('../image/icons/facebook.svg');
const twitterIcon = require('../image/icons/twitter.svg');
const { waterRegister, mapService, googleAnalytics } = config;

const WATER_SOURCE_BASE_URL = '/api/water-source/v2';
const STATIONS_BASE_URL = '/stations/v1';
const SERVICE_BASE_URL = '/api/services';
const WNSW_WATER_MANAGEMENT_MAP_SERVICE = mapService.server;
const TELEMETERED_BORES_MAP_SERVICE = mapService.telemeteredBoresServer;
const BARWON_DARLING_FLOW_CLASS_MAP_SERVICE = mapService.flowClassServer;
const GREATER_SYDNEY_MAP_SERVICE = mapService.greaterSydneyServer;
const ALGAL_ALERTS_MAP_SERVICE = mapService.algalAlertsServer;

const {
  MURRAY_REGULATED_RIVER_WATER_SOURCE,
  MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE,
  LACHLAN_REGULATED_RIVER_WATER_SOURCE,
  LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE,
  HUNTER_WSID,
  GREATER_SYDNEY,
  ALL_NSW,
  BARWON_DARLING_WSID,
  LOWER_MACQUIARIE_WSID,
  MARTHAGUY_CREEK_WSID,
  MEHI_RIVER_WSID,
  GINGHAM_WATERCOURSE_WSID,
  LAKE_WYANGALA_ID,
  LAKE_CARGELLIGO_ID,
  LAKE_BREWSTER_ID,
  MENINDEE_LAKES_TOTAL_ID,
  LAKE_VICTORIA,
  FISH_RIVER_WATER_SOURCE,
  MACQUARIE_CUDGEGONG_RIVERS_WSID,
  BORDER_RIVER_WSID,
  PEEL_RIVER_WSID,
  GWYDIR_RIVER_WSID,
  LOWER_NAMOI_RIVER_WSID,
  SYDNEY_BASIN_NORTH_COAST_GROUNDWATER_WSID,
  LACHLAN_FOLD_BELT_MDB_GROUNDWATER_WSID,
  NEW_ENGLAND_FOLD_BELT_COAST_GROUNDWATER_WSID,
  SYDNEY_BASIN_CENTRAL_GROUNDWATER_WSID,
  SYDNEY_BASIN_NEPEAN_GROUNDWATER_WSID,
  COCHRANE_DAM,
  GLENLYON_DAM,
  LAKE_PAMAMAROO,
  LAKE_COPI_HOLLOW,
  OBERON_DAM,
} = WaterSourceConstant.default;

export default Object.freeze({
  // URL
  BASE_URL: getBaseUrl(),
  WNSW_API_BASE_URL: getWnswAPIBaseUrl(),
  WNSW_SERVICES_API_KEY: process.env.REACT_APP_SERVICES_API_KEY,
  WNSW_SERVICES_DATA_API_KEY: process.env.REACT_APP_SERVICES_DATA_API_KEY,
  WNSW_STATIONS_DATA_API_KEY: process.env.REACT_APP_STATIONS_DATA_API_KEY,
  // water source API
  WATER_SOURCE_BASE_URL: WATER_SOURCE_BASE_URL,
  WATER_SOURCE_BY_ID: WATER_SOURCE_BASE_URL + '/%s',
  WATER_SOURCE_STATUS: WATER_SOURCE_BASE_URL + '/%s/status',
  WATER_SOURCE_DROUGHT_FLOOD_RISK: WATER_SOURCE_BASE_URL + '/%s/drought-flood-risk',
  WATER_SOURCE_NEWS: WATER_SOURCE_BASE_URL + '/%s/updates',
  WATER_SOURCE_CONSTRAINTS: WATER_SOURCE_BASE_URL + '/%s/constraints',
  NSW_RESTRICTIONS: WATER_SOURCE_BASE_URL + '/updates',
  LATEST_UPDATES: `${WATER_SOURCE_BASE_URL}/${ALL_NSW}/updates/latest`,
  PRIOR_UPDATES: `${WATER_SOURCE_BASE_URL}/updates/historical`,
  WATER_SOURCE_SURPLUS_LATEST: WATER_SOURCE_BASE_URL + '/%s/surplus/latest',
  WATER_SOURCE_LOSSES_TRANSMISSION: WATER_SOURCE_BASE_URL + '/%s/losses/transmission',
  WATER_SOURCE_LOSSES_END_SYSTEM: WATER_SOURCE_BASE_URL + '/%s/losses/end-system',
  WATER_SOURCE_MINIMAL: WATER_SOURCE_BASE_URL + '/%s/minimal-flow',
  WATER_SOURCE_COMPARISON: WATER_SOURCE_BASE_URL + '/%s/compare-budget',
  WATER_SOURCE_CONDITIONS: WATER_SOURCE_BASE_URL + '/%s/rules/conditions',
  WATER_SOURCE_ACCOUNTING_RULES: WATER_SOURCE_BASE_URL + '/%s/rules/accounting',
  WATER_SOURCE_COMMENTARY: WATER_SOURCE_BASE_URL + '/%s/commentary',
  WATER_SOURCE_ANNUAL_EXTRACTION: WATER_SOURCE_BASE_URL + '/%s/annual-extraction',
  WATER_SOURCE_COMMENTARY_LATEST: WATER_SOURCE_BASE_URL + '/%s/commentary/latest',
  WATER_SOURCE_ALLOCATION: WATER_SOURCE_BASE_URL + '/%s/allocations',
  WATER_SOURCE_DISTRIBUTION: WATER_SOURCE_BASE_URL + '/%s/distribution',
  WATER_SOURCE_BALANCE: WATER_SOURCE_BASE_URL + '/%s/balance',
  WATER_SOURCE_ANNOUNCEMENT: WATER_SOURCE_BASE_URL + '/%s/announcements',
  WATER_SOURCE_ANNOUNCEMENT_ID: WATER_SOURCE_BASE_URL + '/%s/announcements/%s',
  WATER_SOURCE_TRADE: WATER_SOURCE_BASE_URL + '/%s/trades',
  WATER_SOURCE_TRADE_IVT: WATER_SOURCE_BASE_URL + '/%s/trades/ivt',
  WATER_SOURCE_TRADE_IVT_APPLICATION_QUEUE: WATER_SOURCE_BASE_URL + '/%s/trades/ivt/applications',
  WATER_SOURCE_TRADE_NET_ALLOCATION_VOLUME:
    WATER_SOURCE_BASE_URL + '/%s/trades/net-allocation-volume',
  WATER_SOURCE_RAINFALL: WATER_SOURCE_BASE_URL + '/%s/rainfall',
  WATER_SOURCE_USAGE_RELEASE: WATER_SOURCE_BASE_URL + '/%s/usage-release',
  WATER_SOURCE_INTER_VALLEY_TRADE: WATER_SOURCE_BASE_URL + '/%s/trades/inter-valley/aggregate',
  WATER_SOURCE_OVERVIEW_SUMMARY: WATER_SOURCE_BASE_URL + '/overview/summary',
  WATER_SOURCE_OVERVIEW_RESOURCES: WATER_SOURCE_BASE_URL + '/overview/resources',
  WATER_SOURCE_LICENCED_WATER_BALANCE: WATER_SOURCE_BASE_URL + '/%s/licenced-water-balance',
  WATER_SOURCE_ALLOCATION_FORECAST:
    WATER_SOURCE_BASE_URL + '/%s/allocation/general-security-forecast',
  WATER_SOURCE_ROF_COUNTER: WATER_SOURCE_BASE_URL + '/%s/rof-counter',
  WATER_SOURCE_ORDER_USAGE: WATER_SOURCE_BASE_URL + '/%s/usage-order',
  WATER_SOURCE_FLOW_RATE: WATER_SOURCE_BASE_URL + '/%s/river-flow-rate',
  WATER_SOURCE_SALINITY_REPORT: WATER_SOURCE_BASE_URL + '/%s/salinity-reports',
  WATER_SOURCE_HRST_DISCHARGE_STATIONS: WATER_SOURCE_BASE_URL + '/hunter/hrsts-discharge-stations',

  // stations API
  STATIONS_BASE_URL: STATIONS_BASE_URL,
  WATER_SOURCE_STATIONS: STATIONS_BASE_URL + '/water-source/%s',
  WATER_SOURCE_STATIONS_DAM_VOLUME: STATIONS_BASE_URL + '/water-source/%s/dams/volume',
  WATER_SOURCE_STATIONS_INFLOWS: STATIONS_BASE_URL + '/water-source/%s/inflows', // check later
  WATER_SOURCE_BORES: STATIONS_BASE_URL + '/water-source/%s/bores',
  STATION_DAMS_OVERVIEW: STATIONS_BASE_URL + '/dams/overview', // check later
  STATION_STORAGE_OVERVIEW: STATIONS_BASE_URL + '/overview', // check later
  STATION_STREAMFLOW_FORECAST_URL: STATIONS_BASE_URL + '/%s/detail/forecast',
  STATION_DAM_SUMMARY: STATIONS_BASE_URL + '/dams/%s/resources/latest',
  STATION_DAM_RESOURCES_BY_ID: STATIONS_BASE_URL + '/dams/%s/resources',
  STATION_DAM_RESOURCES: STATIONS_BASE_URL + '/water-source/%s/dams/resources',
  STATION_DAM_FORECAST: STATIONS_BASE_URL + '/water-source/%s/dams/resources/forecast', // check later
  STATION_ALL_DAM_LATEST_RESOURCES: STATIONS_BASE_URL + '/dams/resources/all/latest',
  STATION_RIVER_VOLUME: STATIONS_BASE_URL + '/water-source/%s/river-volume',
  STATION_RIVER_VOLUME_LATEST: STATIONS_BASE_URL + '/water-source/%s/river-volume/latest',
  STATION_DAMS_INFO: STATIONS_BASE_URL + '/dams/%s',

  // service API
  GLOBAL_SEARCH: SERVICE_BASE_URL + '/search',
  GLOBAL_SEARCH_SUGGEST: SERVICE_BASE_URL + '/search/suggest',
  GLOBAL_SEARCH_AUTCOMPLETE: SERVICE_BASE_URL + '/search/autocomplete',
  B2C_USER_INFO_BY_EMAIL_URL: SERVICE_BASE_URL + '/b2c/getUserInfoByEmail',
  // B2C_UPDATE_USER_INFO_BY_EMAIL_URL: SERVICE_BASE_URL + '/b2c/updateUserInfoByEmail',
  // B2C_OAUTH_TOKEN_URL: SERVICE_BASE_URL + '/b2c/getOauthToken',
  OBSERVED_RAINFALL_SERVICES_API: SERVICE_BASE_URL + '/bom/observed-rainfall',
  OBSERVED_TEMPERATURE_SERVICES_API: SERVICE_BASE_URL + '/bom/observed-temperature',
  API_TOKEN: SERVICE_BASE_URL + '/token',
  SEND_EMAIL: SERVICE_BASE_URL + '/email',
  SEND_HTML_EMAIL: SERVICE_BASE_URL + '/email/htmlTemplate',
  DATA_DOWNLOAD_REQUEST: SERVICE_BASE_URL + '/download-request',
  DATA_DOWNLOAD_REQUEST_HDD: SERVICE_BASE_URL + '/download-request/heavy-data',
  DATA_DOWNLOAD_BORE_REPORTS_REQUEST:
    SERVICE_BASE_URL + '/download-request/bore-construction-report',
  WEBSITE_NOTIFICATION: SERVICE_BASE_URL + '/website-notification',

  // others
  WATERNSW_DEALINGS_PROCESS: {
    dealings: {
      url: 'https://rg-guidelines.nswlrs.com.au/water_dealings',
      text: 'WATER DEALINGS',
    },
    licensesApllication: {
      url: 'https://www.dpie.nsw.gov.au/water/licensing-and-trade/licensing/how-to-apply-for-a-water-access-licence',
      text: 'LICENCE APPLICATION & FEES',
    },
  },
  EMPTY_CATEGORY_CONDITIONS_HEADING: 'Conditions applying to all licence categories',
  NSW_MAP_SERVICE_BASE_URL: WNSW_WATER_MANAGEMENT_MAP_SERVICE,
  NULL_STATE_CUR_WATER_SOURCE: {
    water_source_id: null,
    water_source_name: null,
    water_source_type: null,
  },
  WATER_REGISTER_APPROVAL_INFO: {
    server: waterRegister.server + '/approval-info',
    param: waterRegister.param1,
  },
  WATER_REGISTER_LICENCE_INFO: {
    server: waterRegister.server + '/licence-info',
    param: waterRegister.param2,
  },
  GEOCODE_ARCGIS_FIND_ADR_URL:
    'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates',
  GEOCODE_ARCGIS_SUGGEST_URL:
    'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest',
  GEOCODE_ARCGIS_REVERSE_GEOCODE_URL:
    'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode',
  NSW_MAP_SERVICE_IDENTIFY: WNSW_WATER_MANAGEMENT_MAP_SERVICE + 'identify',

  // Watersource IDs
  MURRAY_REGULATED_RIVER_WATER_SOURCE: MURRAY_REGULATED_RIVER_WATER_SOURCE,
  MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE: MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE,
  LACHLAN_REGULATED_RIVER_WATER_SOURCE: LACHLAN_REGULATED_RIVER_WATER_SOURCE,
  LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE: LOWER_DARLING_REGULATED_RIVER_WATER_SOURCE,
  GREATER_SYDNEY_WSID: GREATER_SYDNEY,
  ALL_NSW_WSID: ALL_NSW,
  MENINDEE_LAKES_TOTAL_ID: MENINDEE_LAKES_TOTAL_ID,
  LAKE_WYANGALA_ID: LAKE_WYANGALA_ID,
  LAKE_CARGELLIGO_ID: LAKE_CARGELLIGO_ID,
  LAKE_BREWSTER_ID: LAKE_BREWSTER_ID,
  LAKE_VICTORIA: LAKE_VICTORIA,
  HUNTER_RIVER: HUNTER_WSID,
  BARWON_DARLING_UNREGULATED_RIVER_WATER_SOURCE: BARWON_DARLING_WSID,
  FISH_RIVER_WATER_SOURCE: FISH_RIVER_WATER_SOURCE,
  COCHRANE_DAM: COCHRANE_DAM,
  NON_REGULATED_WATER_SOURCE_IDS: [GREATER_SYDNEY, ALL_NSW, BARWON_DARLING_WSID],
  LOSSES_TAB_WATER_SOURCES: [
    MURRUMBIDGEE_REGULATED_RIVER_WATER_SOURCE,
    MACQUARIE_CUDGEGONG_RIVERS_WSID,
    BORDER_RIVER_WSID,
    PEEL_RIVER_WSID,
    GWYDIR_RIVER_WSID,
    LOWER_NAMOI_RIVER_WSID,
  ],
  ACTIVE_MANAGEMENT_WATER_SOURCES: [
    MARTHAGUY_CREEK_WSID,
    BARWON_DARLING_WSID,
    LOWER_MACQUIARIE_WSID,
    GINGHAM_WATERCOURSE_WSID,
    MEHI_RIVER_WSID,
  ],
  WATER_WITH_COMPLICATE_FEATURES: [
    SYDNEY_BASIN_NORTH_COAST_GROUNDWATER_WSID,
    LACHLAN_FOLD_BELT_MDB_GROUNDWATER_WSID,
    NEW_ENGLAND_FOLD_BELT_COAST_GROUNDWATER_WSID,
    SYDNEY_BASIN_CENTRAL_GROUNDWATER_WSID,
    SYDNEY_BASIN_NEPEAN_GROUNDWATER_WSID,
  ],

  // Links
  LINK_ITEMS: [
    { title: 'Home', link: '/' },
    { title: 'Learn', link: '/learn' },
    { title: 'Snapshot', link: '/snapshot' },
    { title: 'Glossary', link: '/glossary' },
    { title: 'Download', link: '/download' },
    { title: 'Login', link: '' },
  ],
  NSW_UPDATES_ITEMS: [
    { title: 'Current Updates', internalLink: '/nsw-update' },
    { title: 'Archived Updates', internalLink: '/archived-updates' },
  ],
  LINK_ITEMS_MOBILE: [
    { title: 'Home', link: '/' },
    { title: 'Search', link: '/search' },
    { title: 'Learn', link: '/learn' },
    { title: 'My alerts', link: '/my-alert', isPrivate: true },
    // { title: "NSW Updates", link: "/nsw-update" },
    // { title: "Archived Updates", link: "/archived-updates" },
    { title: 'Snapshot', link: '/snapshot' },
    { title: 'Glossary', link: '/glossary' },
  ],
  LINK_ITEMS_MINI: [
    { title: 'Home', link: '/m' },
    { title: 'My alerts', link: '/m/my-alert', isPrivate: true },
    { title: 'Favourites', link: '/m/favourites' },
    { title: 'Glossary', link: '/m/glossary' },
    { title: 'Full site', link: '/' },
  ],
  EXTERNAL_LINK_ITEMS: [
    {
      title: 'Access Licence Register',
      link: 'https://www.nswlrs.com.au/Public-Register/WAL-Register',
    },
    {
      title: 'Water Register',
      link: 'https://waterregister.waternsw.com.au/water-register-frame',
    },
    { title: 'iWAS', link: 'https://iwas.waternsw.com.au/iwas/login.iwas' },
    { title: 'WaterNSW', link: 'https://www.waternsw.com.au/' },
    {
      title: 'MDB Portal',
      link: 'https://mdbwip.bom.gov.au/',
    },
  ],
  FOOTER_LINK_ITEMS: [
    {
      title: 'Privacy',
      link: 'https://www.waternsw.com.au/privacy',
      external: true,
    },
    {
      title: 'Accessibility',
      link: 'https://www.waternsw.com.au/accessibility',
      external: true,
    },
    {
      title: 'Copyright',
      link: 'https://www.waternsw.com.au/copyright',
      external: true,
    },
    {
      title: 'Disclaimer',
      link: 'https://www.waternsw.com.au/disclaimer',
      external: true,
    },
  ],
  FOOTER_SOCIAL_LINK_ITEMS: [
    {
      icon: facebookIcon,
      link: 'https://www.facebook.com/WaterNSW/',
      imgAlt: 'Facebook',
    },
    {
      icon: twitterIcon,
      link: 'https://twitter.com/WaterNSW',
      imgAlt: 'Twitter',
    },
  ],
  TAB_OPTIONS: {
    updates: { id: 'UPDATES', text: 'Updates', link: '/updates' },
    allocation: { id: 'ALLOCATION', text: 'Allocation', link: '/allocation' },
    storage: { id: 'STORAGE', text: 'Storage', link: '/storage' },
    riverData: { id: 'RIVER-DATA', text: 'Gauges', link: '/river-data' },
    losses: { id: 'LOSSES', text: 'Losses', link: '/losses' },
    flowClass: { id: 'FLOW-CLASS', text: 'Access Anncmnts', link: '/flow-class' },
    rules: { id: 'RULES', text: 'Rules', link: '/rules' },
    research: { id: 'RESEARCH', text: 'Historical', link: '/research' },
    trading: { id: 'TRADING', text: 'Trading', link: '/trading' },
    rainfall: { id: 'RAINFALL', text: 'Rainfall', link: '/rainfall' },
    usage: { id: 'USAGE', text: 'Usage', link: '/usage' },
    pollution: { id: 'POLLUTION', text: 'Pollution', link: '/pollution-monitoring' },
    salinity: { id: 'HRSTS', text: 'HRSTS', link: '/hrsts' },
  },

  // Size
  SCREEN_SM: 576,
  SCREEN_MD: 768,
  SCREEN_LG: 992,
  SCREEN_XL: 1200,
  LANDSCAPE: 'landscape',
  PORTRAIT: 'portrait',
  MAP_MARKER_SIZE: { iconSize: [26, 26], iconAnchor: [13, 13] },
  MAP_MARKER_SIZE_MOBILE: { iconSize: [22, 22], iconAnchor: [11, 11] },

  // Map Service
  GTAG_ID: googleAnalytics.gTagId,
  GTM_TAG: googleAnalytics.gtmTag,
  RAINFALL_OUTLOOK_MAP_SERVICE:
    mapService.climateOutlookServer + '/Seasonal_Rainfall_Outlook_IDCKO6MC1M/MapServer',
  TEMPERATURE_OUTLOOK_MAP_SERVICE:
    mapService.climateOutlookServer + '/Seasonal_Temperature_Outlook/MapServer',
  TELEMETERED_BORES_MAP_SERVICE: TELEMETERED_BORES_MAP_SERVICE,
  BARWON_DARLING_FLOW_CLASS: BARWON_DARLING_FLOW_CLASS_MAP_SERVICE,
  BARWON_DARLING_PUMP_STATUS: mapService.pumpStatusServer,
  GREATER_SYDNEY_MAP: GREATER_SYDNEY_MAP_SERVICE + '/FeatureServer',
  GREATER_SYDNEY_MAP_IDENTIFY: GREATER_SYDNEY_MAP_SERVICE + '/MapServer/identify',
  ALGAL_ALERTS_MAP: ALGAL_ALERTS_MAP_SERVICE,

  // Misc
  ACTIVATE_ACTIVE_MANAGEMENT: true,
  SHOW_PUMPS: false,
  INTERACTION_TYPE: {
    waterSource: 'WATERSOURCE_TYPE',
    location: 'LOCATION_TYPE',
    navigation: 'NAV_INTERACTION_TYPE',
    button: 'BUTTON_INTERACTION_TYPE',
    tab: 'TAB_INTERACTION_TYPE',
    dataDownload: 'DATA_DOWNLOAD_INTERACTION_TYPE',
  },
  WATER_SOURCE_TYPE: {
    regulated: 'REG',
    unregulated: 'UNREG',
    groundwater: 'GW',
  },
  TOTAL_STORAGE_MENINDEE_LAKES_FLAG: true,
  STORAGE_DATE_FORMAT: 'DD-MMM-YYYY 09:00',
  WATER_DATA_API_DATE_FORMAT: 'DD-MMM-YYYY HH:mm',
  DUCKMALOI_WATER_QUALITY_DOWNLOAD_LINK: process.env.REACT_APP_POLLUTION,
  SPECIAL_SITES: [LAKE_VICTORIA],
  SPECIAL_DAM_SITES: [
    GLENLYON_DAM,
    LAKE_PAMAMAROO,
    LAKE_COPI_HOLLOW,
    OBERON_DAM,
    COCHRANE_DAM,
    MENINDEE_LAKES_TOTAL_ID,
  ],
  EXCLUDED_SITES: ['42510101', '42510102'],
});

function getBaseUrl() {
  return process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : '';
}

function getWnswAPIBaseUrl() {
  return process.env.REACT_APP_WNSW_API_BASE_URL ? process.env.REACT_APP_WNSW_API_BASE_URL : '';
}
