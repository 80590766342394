const BASE_URL = '/api/services/water-data';
const WNSW_API_SERVICES_BASE_URL = '/services/v1';
const WNSW_API_SERVICES_DATA_BASE_URL = '/services-water-data/v1';
const PROD_ENV = window.location.origin === 'https://waterinsights.waternsw.com.au';

export default Object.freeze({
  WATER_DATA_API_SITE_METADATA: BASE_URL + '/site-metadata',
  WATER_DATA_API_RATING_TABLE: BASE_URL + '/rating-tables',
  WATER_DATA_TIMESERIES: {
    groundwater: {
      '15-minute': BASE_URL + '/groundwater-data',
      hourly: BASE_URL + '/groundwater-data',
      daily: BASE_URL + '/groundwater-data',
      monthly: BASE_URL + '/groundwater-data',
      manual: BASE_URL + '/groundwater-data',
      download:
        (PROD_ENV ? WNSW_API_SERVICES_DATA_BASE_URL : BASE_URL) + '/groundwater-data-download',
    },
    site: {
      latest: WNSW_API_SERVICES_BASE_URL + '/latest',
      download: (PROD_ENV ? WNSW_API_SERVICES_DATA_BASE_URL : BASE_URL) + '/download',
      monthly: (PROD_ENV ? WNSW_API_SERVICES_DATA_BASE_URL : BASE_URL) + '/download',
      daily: (PROD_ENV ? WNSW_API_SERVICES_DATA_BASE_URL : BASE_URL) + '/download',
      summary: WNSW_API_SERVICES_BASE_URL + '/summary',
    },
    meteorological: {
      '15-minute': WNSW_API_SERVICES_DATA_BASE_URL + '/meteorological-data',
      hourly: WNSW_API_SERVICES_DATA_BASE_URL + '/meteorological-data',
      daily: WNSW_API_SERVICES_DATA_BASE_URL + '/meteorological-data',
      monthly: WNSW_API_SERVICES_DATA_BASE_URL + '/meteorological-data',
      download: BASE_URL + '/meteorological-data-download',
    },
    inflow: (PROD_ENV ? WNSW_API_SERVICES_DATA_BASE_URL : BASE_URL) + '/inflow-data',
    eflow: (PROD_ENV ? WNSW_API_SERVICES_DATA_BASE_URL : BASE_URL) + '/eflow-data',
  },
  SITE_TYPE: {
    Storage: 'Storage',
    StreamGauge: 'StreamGauge',
    Weirs: 'Weirs',
    Groundwater: 'Groundwater',
    Meteorological: 'Meteorological',
    'Metro dam storage': 'Storage',
  },
  API_FIELDS: {
    Inflow: 'TotalCatchmentInflow',
    InflowSurface: 'StorageInflow',
    ReleaseSurface: 'StorageReleases',
    Release: 'ActualReleaseMade',
    Volume: 'Volume',
  },
  HYDROMETRIC_VARIABLES_MAP: {
    ActiveStoragePercentage: 'volume_perc',
    ActiveStorageVolume: 'volume',
    Volume: 'volume',
    StorageInflow: 'inflow',
    StorageReleases: 'release',
    TotalStorageVolume: 'volume',
    FlowRate: 'flow_rate',
    ActualReleaseMade: 'environmental_release',
    TotalCatchmentInflow: 'inflow',
    WaterTemperature: 'temperature',
    Conductivity: 'salinity',
    StorageWaterLevel: 'level',
    StreamWaterLevel: 'level',
    DissolvedOxygenSaturation: 'dissolved_oxygen_saturation',
    DissolvedOxygen: 'dissolved_oxygen',
    GroundwaterDepthBelowSurfaceLevel: 'water_level',
    pH: 'pH',
    Turbidity: 'turbidity',
    Rainfall: 'rainfall',
    HrstsDischargePointStatus: 'hrsts_status',
  },
  DAM_STORAGE_VARIABLES: [
    'ActiveStorageVolume',
    'ActiveStoragePercentage',
    'StorageInflow',
    'StorageReleases',
  ],
  METRO_DAM_STORAGE_VARIABLES: ['ActiveStorageVolume', 'ActiveStoragePercentage'],
  METRO_DAMS: [
    { id: '212205', name: 'Nepean Dam' },
    { id: '212211', name: 'Avon Dam' },
    { id: '212212', name: 'Wingecarribee Reservoir' }, //GLENQUARRY CUT @  WINGECARRIBEE STORAGE
    { id: '212220', name: 'Cordeaux Dam' },
    { id: '212232', name: 'Cataract Dam' },
    { id: '212243', name: 'Warragamba Dam' },
    { id: '212287', name: 'Blue Mountains Dams' },
    { id: '213210', name: 'Woronora Dam' },
    { id: '213240', name: 'Prospect Reservoir' },
    { id: '215212', name: 'Tallowa Dam' },
    { id: '215235', name: 'Fitzroy Falls Reservoir' }, //YARRUNGA CREEK AT FITZROY FALLS DAM
  ],
  METRO_DAMS_STORAGE: 'Metro dam storage',
  GROUNDWATER: 'Groundwater',
  API_DATE_FORMAT: 'DD-MMM-YYYY HH:mm',
  AUTOQC_DATE_RESTRICTION: '01-Jan-2023 00:00',
});
