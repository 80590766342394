// Library
import React, { useState, useContext, useEffect } from 'react';
import { isEmpty, cloneDeep } from 'lodash';
import moment from 'moment';

// Styles
import './graphAndDownloadSelect.scss';

// Component
import GraphAndDownloadDatePicker from './GraphAndDownloadDatePicker';
import GraphAndDownloadDropdown from './GraphAndDownloadDropdown';
import GraphAndDownloadSubmitBtn from './GraphAndDownloadSubmitBtn';

// Helper
import {
  getHydrometricOptions,
  processRequestedHydrometric,
  reselectHydroByNewOptions,
} from './GraphAndDownloadHelper';

// Context
import { DownloadContext } from '../../../../../../contexts/DownloadContext';
// Constant
import constants from '../../DataDownloadConstants';
const component = 'graph-and-download-select';

const GraphAndDownloadSelect = () => {
  const {
    downloadType,
    selectedStations,
    hydrometric,
    setHydrometric,
    dataType,
    setDataType,
    interval,
    setInterval,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    requestedHydrometric,
    setRequestedHydrometric,
  } = useContext(DownloadContext);
  const [hydrometricOptions, setHydrometricOptions] = useState([]);
  const [intervalOptions, setIntervalOptions] = useState(constants.INTERVAL_SELECTIONS);
  const [disableDatePicker, setDisableDatePicker] = useState(interval.id !== '0');
  const [timePeriod, setTimePeriod] = useState(constants.TIME_PERIOD[4]);
  const isMetro = downloadType === constants.METRO_DAM;
  const isFlowData = !isEmpty(hydrometric) && ['Inflow', 'Eflow'].includes(hydrometric[0].id);
  const converted = JSON.stringify(selectedStations);

  useEffect(() => {
    setStartDate(moment().subtract(timePeriod[timePeriod.unit], timePeriod.unit).valueOf());
  }, []);

  useEffect(() => {
    (async () => {
      setIntervalOptions(constants.INTERVAL_SELECTIONS);
      if (selectedStations && selectedStations[0].siteType === 'Groundwater') {
        const original = cloneDeep(constants.INTERVAL_SELECTIONS);
        const options = [
          {
            id: 'manual',
            name: 'manual',
            disabled: false,
          },
        ].concat(original);
        setIntervalOptions(options);
      }
      let options = await getHydrometricOptions(downloadType, selectedStations);
      processRequestedHydrometric(
        requestedHydrometric,
        options,
        setHydrometric,
        setRequestedHydrometric,
      );
      // remove Volume from the variable list
      options = options.filter(opt => opt.id !== 'Volume');
      reselectHydroByNewOptions(options, setHydrometric);
      setHydrometricOptions(options);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [converted, requestedHydrometric, setHydrometric, setRequestedHydrometric]);

  const updateIntervalOpt = selected => {
    const original = cloneDeep(constants.INTERVAL_SELECTIONS);
    if (selected === 'Inflow') {
      setIntervalOptions(original.filter(item => item.name !== 'hourly'));
      setDataType(constants.DATA_TYPES[1]);
    } else if (selected === 'Eflow') {
      setIntervalOptions(
        original.filter(item => item.name !== 'hourly' && item.name !== 'instantaneous'),
      );
      setDataType(constants.DATA_TYPES[1]);
    } else {
      setIntervalOptions(original);
      setDataType(constants.DATA_TYPES[0]);
    }
  };

  const onIntervalChange = value => {
    setTimePeriod(value);
    setDisableDatePicker(value.id !== '0');
    setStartDate(moment(endDate).subtract(value[value.unit], value.unit).valueOf());
  };

  const onEndDateChange = value => {
    setEndDate(value);
    setStartDate(moment(value).subtract(timePeriod[timePeriod.unit], timePeriod.unit).valueOf());
  };

  return (
    <div className={component}>
      <div className={`${component}-filters`}>
        {isMetro ? (
          <GraphAndDownloadDropdown
            options={hydrometricOptions}
            selectedOptions={
              isEmpty(hydrometric) ? { id: 'empty', name: 'please select' } : hydrometric[0]
            }
            onChange={item => {
              setHydrometric([item]);
              updateIntervalOpt(item.id);
            }}
            listType="radio"
          />
        ) : (
          <GraphAndDownloadDropdown
            options={hydrometricOptions}
            selectedOptions={hydrometric}
            onChange={setHydrometric}
            listType="checkbox"
          />
        )}
        {!isFlowData ? (
          <>
            <p> data at </p>
            <GraphAndDownloadDropdown
              options={constants.DATA_TYPES}
              selectedOptions={dataType}
              onChange={setDataType}
              listType="radio"
            />
            <p> quality at </p>
          </>
        ) : (
          <p> at </p>
        )}
        <GraphAndDownloadDropdown
          options={intervalOptions}
          selectedOptions={interval}
          onChange={setInterval}
          listType="radio"
        />
        <p> interval for </p>
        <GraphAndDownloadDropdown
          options={constants.TIME_PERIOD}
          selectedOptions={timePeriod}
          onChange={e => onIntervalChange(e)}
          listType="radio"
        />
        <p> from </p>
        <GraphAndDownloadDatePicker
          selectedDate={startDate}
          setSelectedDate={setStartDate}
          disabled={disableDatePicker}
        />
        <p> to </p>
        <GraphAndDownloadDatePicker
          selectedDate={endDate}
          setSelectedDate={e => onEndDateChange(e)}
        />
      </div>
      <GraphAndDownloadSubmitBtn />
    </div>
  );
};

export default GraphAndDownloadSelect;

GraphAndDownloadSelect.propTypes = {};
