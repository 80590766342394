// Libraries
import React, { useContext, useState } from 'react';

// Styles
import './mandatoryConditions.scss';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Components
import Modal from '../modal/Modal';
import DictionaryModalContent from '../modal/DictionaryModalContent';
import DealingRulesModalData from '../modal/modalText/DealingRulesModalData';
import InfoButton from '../informationButton/InformationButton';
import ExternalLinkModal from '../modal/ExternalLinkModal';
import RulesLink from './RulesLink';

export default function DealingRules({ conditionsData }) {
  const { isMobile } = useContext(MobileContext);
  const [externalSiteLink, setExternalSiteLink] = useState('');
  const dealingRules = [
    {
      link: conditionsData.water_sharing_plan_summary_url,
      linkText: 'Rules Summary Sheet',
      text: 'A summary of the rules applying to this water source is available in the ',
    },
    {
      link: 'https://rg-guidelines.nswlrs.com.au/land_dealings/dealing_requirements',
      linkText: 'Land Registry Services Dealing Requirements',
      text: 'To make changes to the register of licences (for example transfer of title, a name change, mortgages, death etc) please see the ',
    },
    {
      link: 'https://www.waternsw.com.au/customer-service/water-licensing/applications-and-fees#stay',
      linkText: 'Licence Applications page',
      text: "If you wish to apply for a new <a className='drought-body-link' href='/glossary#licence'> licence</a> or <a className='drought-body-link' href='/glossary#approval'> approval</a> and you are a rural landholder or rural business please see our ",
    },
    {
      link: 'https://www.dpie.nsw.gov.au/water/licensing-and-trade/licensing/how-to-apply-for-a-water-access-licence',
      linkText: ' DCCEEW’s Licence Applications page',
      text: "If you wish to apply for a new <a className='drought-body-link' href='/glossary#licence'> licence</a> and you are not a rural landholder or rural business please see the ",
    },
  ];

  return (
    <div className="conditions">
      <div className="row conditions-title">
        <span>Dealing Rules</span>
        <InfoButton targetDataId="#dealingModalCenter" />
      </div>
      <Modal
        id="dealingModalCenter"
        title="Understanding water rules"
        body={<DictionaryModalContent data={DealingRulesModalData} />}
      />
      <div className="conditions-body">
        <div className="conditions-inner-body">
          {dealingRules.map(para => (
            <RulesLink
              key={para.linkText}
              link={para.link}
              linkText={para.linkText}
              text={para.text}
              setExternalSiteLink={setExternalSiteLink}
              targetId={'#externalLinkDealing'}
            />
          ))}
        </div>
        {isMobile && <ExternalLinkModal link={externalSiteLink} modalId="externalLinkDealing" />}
      </div>
    </div>
  );
}
