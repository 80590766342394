// Library
import { isEmpty, cloneDeep, isNumber, merge, keyBy, values, groupBy } from 'lodash';
import moment from 'moment';

// Helpers
import { formatNumberDecimals, sortArrayByKeyAsc } from '../../helpers/Utils';
import { genDummyDataForTimePeriod } from '../../helpers/ProcessDataHelper';
import { getCurrentDate, roundDownTimeStamp, formatDate } from '../../helpers/TimeUtils';
import { getStreamFlowForecastData } from '../../helpers/ApiHelper';
import { getSummarySurfaceWaterData } from '../../helpers/WaterDataApiHelper';

// Constants
import APIConstant from '../../constants/APIResponseConstant';
import mapFilterConstant from '../../constants/MapFilterConstants';
import apiConstants from '../../constants/WaterDataAPIConstants';
const { GAUGE, WEIR } = mapFilterConstant.MAP_POPUP_ITEMS;
const FORECAST_DATA_POINT_HOUR = '09';

const findLastIndex = (array, key) => {
  var index = array
    .slice()
    .reverse()
    .findIndex(item => item[key] >= 0 || item[key]);
  var count = array.length - 1;
  var finalIndex = index >= 0 ? count - index : index;
  return finalIndex;
};

export const formatTooltip = (value, name, item, unit) => {
  if (item.payload && item.payload.value === item.payload.forecastValue) {
    if (name.includes('forecast')) {
      return [];
    }
  }
  if (item.payload && item.payload.filler && !item.payload.value && item.payload.forecastValue) {
    if (moment(item.payload.date).format('HH') !== FORECAST_DATA_POINT_HOUR) {
      return [];
    }
  }
  const formattedValue = formatNumberDecimals(value);
  return [unit ? `${formattedValue} ${unit}` : formattedValue, name];
};

const removeEmptyRiverProp = (type, rawData, resultStructure, riverProp) => {
  const clonedStructure = cloneDeep(resultStructure);
  if (type === GAUGE.name) {
    const noneNullValueItem = rawData.find(dateItem => dateItem[riverProp]);
    if (!noneNullValueItem) {
      clonedStructure.splice(
        clonedStructure.findIndex(sortedItem => sortedItem.dataKey === riverProp),
        1,
      );
    }
  }
  return clonedStructure;
};

export const sortDataArrayBasedOnStructure = (array, type, dataProps) => {
  let sorted = removeEmptyRiverProp(type, array, dataProps, APIConstant.GAUGE.temperature);
  sorted = removeEmptyRiverProp(type, array, sorted, APIConstant.GAUGE.salinity);
  sorted = removeEmptyRiverProp(type, array, sorted, APIConstant.GAUGE.dissolved_oxygen);
  sorted = removeEmptyRiverProp(type, array, sorted, APIConstant.GAUGE.dissolved_oxygen_saturation);
  sorted = removeEmptyRiverProp(type, array, sorted, APIConstant.GAUGE.pH);
  sorted = removeEmptyRiverProp(type, array, sorted, APIConstant.GAUGE.rainfall);
  array.forEach(dateItem => {
    sorted.forEach(sortedItem => {
      sortedItem.content.push({
        date: dateItem.date,
        qualityCode: dateItem[sortedItem.dataKey]?.qualityCode,
        value:
          sortedItem.name === mapFilterConstant.VOLUME && dateItem[sortedItem.dataKey]?.value
            ? dateItem[sortedItem.dataKey]?.value / 1000
            : dateItem[sortedItem.dataKey]?.value,
      });
    });
  });
  return sorted;
};

export const fillInDummyData = (dayPeriod, startingDay, dataStations) => {
  const dummyData = genDummyDataForTimePeriod({
    timePeriod: dayPeriod,
    startingTime: moment(getCurrentDate(), 'YYYY-MM-DD')
      .add(startingDay, 'days')
      .format('YYYY-MM-DD'),
  });
  return dummyData.map(dataPoint => {
    let resultData = dataPoint;
    dataStations.forEach(station => {
      if (
        dataPoint.date === moment(station.date, apiConstants.API_DATE_FORMAT).format('YYYY-MM-DD')
      ) {
        resultData = { ...station };
      }
    });
    return resultData;
  });
};

const fillInMissingDataPoint = (content, lastIndexOfActualValue) => {
  if (content && !isEmpty(content) && content[lastIndexOfActualValue - 1]) {
    const lastActualValueDate = content[lastIndexOfActualValue - 1].date;
    const lastValueDate = content[content.length - 1].date;
    const duration = moment
      .duration(moment(lastValueDate).diff(moment(lastActualValueDate)))
      .as('hours');

    // fill in missing data point in every hour from the time where actual value is captured until the last element
    const missingDataArray = genDummyDataForTimePeriod({
      timePeriod: duration,
      timeUnit: 'hours',
      startingTime: lastValueDate,
      dateFormat: 'YYYY-MM-DD HH:mm:ss',
    });

    // merge both missing data and actual data
    let merged = values(merge(keyBy(content, 'date'), keyBy(missingDataArray, 'date')));
    merged = sortArrayByKeyAsc(merged, 'date');

    // define the number of days where forecast value is captured and loop through the list to fill in the dummy value based on differences
    const forecastDays = merged.filter(item => item.forecastValue && !item.value);

    for (let i = 0; i < forecastDays.length; i++) {
      const key = i === 0 ? 'value' : 'filler';
      const upperIndex = findLastIndex(merged, key);
      const lowerIndex = merged.findIndex(item => item.date === forecastDays[i].date);

      if (merged[upperIndex]) {
        const subKey = i === 0 ? 'value' : 'forecastValue';
        const difference = merged[upperIndex][subKey] - forecastDays[i].forecastValue;
        const offset = difference / (lowerIndex - upperIndex - 1);
        merged.forEach((item, index) => {
          if (index >= upperIndex && index < lowerIndex && !item.forecastValue) {
            item.forecastValue =
              index === upperIndex ? item.value : merged[index - 1].forecastValue - offset;
            item.filler = true;
          }
        });
      }
    }
    return merged;
  }
  return content;
};

export const getGraphData = (arrData, target, toggleOptions, dataStations, dataStationsHourly) => {
  let targetItem = arrData.find(
    dataItem =>
      target && dataItem.dataKey.replaceAll('_', ' ').toLowerCase() === target.toLowerCase(),
  );
  if (!isEmpty(targetItem)) {
    targetItem.content = targetItem.content.filter(data => {
      return typeof data.value !== 'undefined';
    });
  }

  if (target === toggleOptions[0] && targetItem) {
    const hasForecastData = dataStations.some(data =>
      Object.prototype.hasOwnProperty.call(data, 'flow_rate_forecast'),
    );
    const targetHasForecastData = targetItem.content.some(data =>
      Object.prototype.hasOwnProperty.call(data, 'forecast_value'),
    );

    if (hasForecastData && !targetHasForecastData) {
      targetItem.content = dataStationsHourly.map(
        ({ flow_rate_forecast: rawForecastValue, flow_rate: value, ...rest }) => {
          const forecastValue =
            isNumber(value) && isNumber(rawForecastValue) ? value : rawForecastValue;
          return { ...rest, forecastValue, value };
        },
      );
      const lastIndex = targetItem.content.findIndex(item => typeof item.value === 'undefined');
      if (targetItem.content[lastIndex - 1]) {
        targetItem.content[lastIndex - 1].forecastValue = targetItem.content[lastIndex - 1].value;
      }
      targetItem.content = fillInMissingDataPoint(targetItem.content, lastIndex);
    }
  }
  return targetItem;
};

export const decideInterval = (stationType, timeSpan) => {
  if (stationType === WEIR.name) {
    return 'daily';
  }

  if (timeSpan?.id === '167 hours' || timeSpan?.id === '7 days') {
    return '15-minute';
  } else {
    return 'daily';
  }
};

export const determineDefaultGraphStructure = (interval, timeValue, timeUnit, timeOrigin) => {
  let adjustedValue;
  if (timeUnit === 'hours') {
    adjustedValue = timeValue * 60;
  } else if (timeUnit === 'years') {
    adjustedValue = timeValue * 60 * 24 * 365;
  }

  if (interval === '15-minute') {
    return {
      timePeriod: adjustedValue / 15,
      timeUnit: 'minutes',
      timeInterval: 15,
      startingTime: roundDownTimeStamp(timeOrigin, apiConstants.API_DATE_FORMAT, interval),
    };
  } else if (interval === 'daily') {
    return {
      timePeriod: adjustedValue / (60 * 24),
      timeUnit: 'days',
      timeInterval: 1,
      startingTime: roundDownTimeStamp(timeOrigin, apiConstants.API_DATE_FORMAT, interval),
    };
  }
};

//TODO this function can be more generalised to be merged with other filling functions
export const fillTimeGaps = ({
  timeSeriesData,
  datetimeFormat = 'DD-MMM-YYYY HH:mm',
  datetimeKey,
  intervalValue,
  intervalUnit,
}) => {
  let filledTimeSeries = [];
  for (let i = 0; i < timeSeriesData.length - 1; i++) {
    const currentDataPoint = timeSeriesData[i];
    const nextDataPoint = timeSeriesData[i + 1];
    filledTimeSeries.push(currentDataPoint);
    const timeDiff = moment(nextDataPoint[datetimeKey]).diff(
      moment(currentDataPoint[datetimeKey]),
      intervalUnit,
    );
    if (timeDiff > intervalValue) {
      const numGaps = Math.floor((timeDiff - 1) / intervalValue);
      for (let j = 1; j <= numGaps; j++) {
        const gapTime = moment(currentDataPoint[datetimeKey], datetimeFormat).add(
          j * intervalValue,
          intervalUnit,
        );
        const roundedGapTime = roundDownTimeStamp(gapTime, datetimeFormat, intervalUnit);
        filledTimeSeries.push({
          [datetimeKey]: roundedGapTime.format(datetimeFormat),
          data: undefined,
        });
      }
    }
  }
  filledTimeSeries.push(timeSeriesData[timeSeriesData.length - 1]);
  return filledTimeSeries;
};

export const calcCumulativeValue = data => {
  let cumulativeValue = 0;
  let result = [];
  for (let i = 0; i < data.length; i++) {
    cumulativeValue += data[i].value;
    result.push({ ...data[i], cumulativeValue });
  }
  return result;
};

export const getStreamFlowForecastDataPromise = stationId => {
  return new Promise((resolve, reject) => {
    try {
      getStreamFlowForecastData(stationId, function (result) {
        const lastValueDate = result[result.length - 1]?.date;
        const duration = moment
          .duration(
            moment(lastValueDate, 'YYYY-MM-DD HH:mm:ss').diff(
              moment().format('YYYY-MM-DD HH:mm:ss'),
            ),
          )
          .as('hours');

        // fill in missing data point in every hour from the time where actual value is captured until the last element
        const missingDataArray = genDummyDataForTimePeriod({
          timePeriod: Math.floor(duration + 1),
          timeUnit: 'hours',
          startingTime: lastValueDate,
          dateFormat: 'YYYY-MM-DD HH:mm:ss',
        });
        let mergedForecastData = values(
          merge(keyBy(result, 'date'), keyBy(missingDataArray, 'date')),
        );
        mergedForecastData = sortArrayByKeyAsc(mergedForecastData, 'date');
        const labledForecastData = { forecastData: { data: mergedForecastData } };

        resolve(labledForecastData);
      });
    } catch (error) {
      console.error(error);
      reject([]);
    }
  });
};

export const fetchDataFromWaterAPI = async (
  timeSpan,
  stationData,
  interval,
  hydroVariables,
  isWeir,
) => {
  try {
    let { results, qualities } = await getSummarySurfaceWaterData(
      stationData.id,
      interval,
      hydroVariables.join(','),
      timeSpan?.id,
    );
    const allHydroData = {};
    if (results && !isEmpty(results)) {
      const groupedByHydrometrics = groupBy(results.flat(), 'variableName');

      if (!isWeir) {
        for (const [hydrometricVariable, hydrometricData] of Object.entries(
          groupedByHydrometrics,
        )) {
          if (hydroVariables.includes(hydrometricVariable)) {
            allHydroData[
              apiConstants.HYDROMETRIC_VARIABLES_MAP[hydrometricVariable].toLowerCase()
            ] =
              hydrometricVariable === 'Rainfall'
                ? { data: calcCumulativeValue(hydrometricData), qualities }
                : { data: hydrometricData, qualities }; // remove the gaps
          }
        }
      } else {
        for (const [hydrometric, hydrometricData] of Object.entries(groupedByHydrometrics)) {
          allHydroData[apiConstants.HYDROMETRIC_VARIABLES_MAP[hydrometric]] = {
            data: hydrometricData,
            qualities,
          };
        }
      }
    }

    return allHydroData;
  } catch (e) {
    console.error(e);
    return [];
  }
};

export const fetchHydroDataAndForecast = async (
  timeSpan,
  station,
  interval,
  variables,
  isWeir,
  setShowSpinner,
  defaultTimeSpan,
  hasForecast,
) => {
  setShowSpinner(true);
  let variableList = [variables.filter(item => item !== 'Rainfall')];
  if (variables.includes('Rainfall')) {
    variableList.push(['Rainfall']);
  }
  const result = await Promise.all([
    // for rainfall, get only daily data
    fetchDataFromWaterAPI(timeSpan, station, interval, variableList[0], isWeir),
    ...(variableList.length > 1
      ? [fetchDataFromWaterAPI(timeSpan, station, 'daily', variableList[1], isWeir)]
      : []),
    !isWeir &&
      hasForecast &&
      timeSpan.id === defaultTimeSpan.id &&
      getStreamFlowForecastDataPromise(station.station_id),
  ]);
  const mergedData = Object.assign({}, ...result);
  setShowSpinner(false);
  return mergedData;
};

export const getGraphDateArray = dataObj => {
  if (isEmpty(dataObj)) {
    return [];
  }

  const filtered = dataObj.content.filter(data => {
    return moment(data.date).format('HH:mm') === '09:00';
  });
  const dateArray = !isEmpty(filtered) ? filtered.map(item => item.date) : [];
  return dateArray;
};

export const formatForecastData = allHydroData => {
  const dateFormat = 'YYYY-MM-DD HH:mm';
  let mergedData;
  const flowRateData = allHydroData['flow_rate'].data;
  const foreCastData = allHydroData['forecastData'] ? allHydroData['forecastData'].data : [];
  const lastIndex = allHydroData['flow_rate'].data.length - 1;

  // format water api data
  if (!isEmpty(flowRateData)) {
    mergedData = flowRateData.map(dataItem => {
      const resItem = {
        value: dataItem.value,
        forecastValue: undefined,
        ...dataItem,
      };
      return resItem;
    });
  } else {
    return {};
  }

  // Process forecast data
  if (!isEmpty(foreCastData)) {
    const forecastData = foreCastData.reduce((arr, rawItem) => {
      const formattedDate = formatDate(
        rawItem.date,
        `${dateFormat}:ss`,
        apiConstants.API_DATE_FORMAT,
      );
      if (
        moment(formattedDate, apiConstants.API_DATE_FORMAT).isAfter(
          getCurrentDate(apiConstants.API_DATE_FORMAT),
        )
      ) {
        const res = {
          forecastValue: rawItem.flow_rate_forecast,
          timeStamp: formattedDate,
          value: undefined,
        };
        arr.push(res);
      }
      return arr;
    }, []);
    mergedData.push(...forecastData);
    mergedData[lastIndex].forecastValue = mergedData[lastIndex].value;
    delete allHydroData.forecastData;
  }
  return {
    ...allHydroData,
    flow_rate: { data: [...mergedData], qualities: allHydroData['flow_rate'].qualities },
  };
};

export const formatDataForGraph = (rawData, isWeir) => {
  if (!isEmpty(rawData.data)) {
    const dateFormat = 'YYYY-MM-DD HH:mm';
    const name = rawData.data[0].variableName;
    const unit =
      rawData.data[0].unitOfMeasure === 'Percentage (%)'
        ? '%'
        : rawData.data[0].variableName.includes('StorageVolume')
        ? 'GL'
        : rawData.data[0].unitOfMeasure;
    let content;
    if (isWeir) {
      content = rawData.data
        .filter(dataItem => dataItem.value)
        .map(item => {
          return {
            value: item.value,
            qualityCode: item.qualityCode,
            date: formatDate(item.timeStamp, apiConstants.API_DATE_FORMAT, dateFormat),
          };
        });
    } else {
      content = rawData.data.map(dataItem => {
        const resItem = {
          ...(dataItem.variableName !== 'Rainfall' ? { value: dataItem.value } : {}),
          qualityCode: dataItem.qualityCode,
          date: formatDate(dataItem.timeStamp, apiConstants.API_DATE_FORMAT, dateFormat),
          forecastValue: dataItem.forecastValue,
          ...(dataItem.variableName === 'Rainfall'
            ? { Rainfall: dataItem.value, 'Cumulative rainfall': dataItem.cumulativeValue }
            : {}),
        };
        return resItem;
      });
    }
    return {
      content,
      unit,
      name: apiConstants.HYDROMETRIC_VARIABLES_MAP[name],
      qualities: rawData.qualities,
    };
  } else return {};
};

export const setupHydrometrics = (
  allHydrometricData,
  stationData,
  timeSpan,
  dataProps,
  setHydroOptions,
  setResourceActive,
  activeHydrometric,
  isWeir,
) => {
  if (!isEmpty(allHydrometricData) && !isEmpty(stationData)) {
    const latestHydroOptions = !isEmpty(stationData.hydrometric_types)
      ? dataProps
          .filter(item => {
            let isHydrometic = isWeir
              ? allHydrometricData[item.dataKey]
              : allHydrometricData[timeSpan.id][item.dataKey.toLowerCase()];
            return isHydrometic;
          })
          .map((item, index) => {
            return {
              id: `id-${index}`,
              displayName: item.name.toLowerCase() === 'ph' ? item.name : item.name.toUpperCase(),
              hydroName: item.dataKey.toUpperCase(),
              waterDataKey: item.waterDataKey,
              unit: item.unit,
            };
          })
      : [];
    if (!isEmpty(latestHydroOptions)) {
      setHydroOptions(latestHydroOptions);
      setResourceActive(
        activeHydrometric && latestHydroOptions.find(element => element.id === activeHydrometric)
          ? activeHydrometric
          : 'id-0',
      );
    }
  }
};

export const arePropsEqual = (prevProps, nextProps) => {
  return prevProps.stationData.station_id === nextProps.stationData.station_id;
};
