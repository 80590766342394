// Libraries
import axios from 'axios';
import { isEmpty, cloneDeep, isNull } from 'lodash';
import util from 'util';
import { formatLabelWaterYear, currentFinancialYear, getCurrentDate } from './TimeUtils';
// Components
import { fetchDataFromApi } from './ApiHelper';
import constants from '../constants/Constants';

const apiBaseURL = constants.BASE_URL;
const TIMEOUT = 15000;

export const getLossesWaterSource = async (waterSource, waterYears, setLossesData) => {
  try {
    const params = {
      url: util.format(constants.WATER_SOURCE_LOSSES_TRANSMISSION, waterSource.water_source_id),
      params: { waterYears },
    };
    const result = await axios.get(apiBaseURL + params.url, {
      timeout: TIMEOUT,
      params: params.params,
    });
    if (result) {
      setLossesData(
        !isEmpty(result.data) &&
          !isEmpty(result.data.losses) &&
          !isEmpty(result.data.losses[0].sites)
          ? result.data.losses[0].sites
          : [],
      );
    }
  } catch (error) {
    return error;
  }
};

export const getComparedBudgetFromWaterSource = async (
  waterSource,
  setCompareBudgetData,
  siteId,
) => {
  const params = {
    url: util.format(constants.WATER_SOURCE_COMPARISON, waterSource.water_source_id),
  };
  fetchDataFromApi(params, 'losses').then(
    result => {
      if (result) {
        setCompareBudgetData(
          !isEmpty(result) && !isEmpty(result[0].sites[siteId]) ? result[0].sites[siteId] : [],
        );
      }
    },
    error => {
      return error;
    },
  );
};

export const getMinimalFlow = async (waterSource, waterYears, setMinimalFlowData) => {
  try {
    const params = {
      url: util.format(constants.WATER_SOURCE_LOSSES_END_SYSTEM, waterSource.water_source_id),
      params: { waterYears },
    };
    fetchDataFromApi(params, 'losses').then(
      result => {
        if (result) {
          setMinimalFlowData(!isEmpty(result) && !isEmpty(result[0].sites) ? result[0].sites : []);
        }
      },
      error => {
        return error;
      },
    );
  } catch (error) {
    return error;
  }
};

export const formatApiResponse = (data, type, dataKey, lastIndexOfActual) => {
  const chartData = cloneDeep(data);
  const dataKeyList = dataKey.split('/');
  if (type === 'losses') {
    for (let i in chartData.resources) {
      Object.keys(chartData.resources[i]).forEach(yearKey => {
        if (yearKey !== 'month') {
          if (dataKey !== 'actual' && i <= lastIndexOfActual + 1) {
            modifyData(chartData.resources[i], chartData.resources[i - 1], yearKey);
          }
          const budgeted = chartData.resources[i][yearKey][dataKeyList[1]];
          const projected = chartData.resources[i][yearKey][dataKeyList[2]];
          const actual = chartData.resources[i][yearKey].actual;

          chartData.resources[i][`${yearKey}-${dataKeyList[1]}`] = budgeted;
          chartData.resources[i][`${yearKey}-${dataKeyList[2]}`] = projected;
          chartData.resources[i][`${yearKey}-actual`] = actual;

          const curWaterYear = formatLabelWaterYear(currentFinancialYear(), ['YYYY', 'YYYY']);
          const curMonth = getCurrentDate('MMMM');
          if (
            dataKey.includes('budgeted/projected') &&
            i !== 0 &&
            curWaterYear === yearKey &&
            chartData.resources[i].month === curMonth
          ) {
            chartData.resources[i - 1][`${yearKey}-projected`] =
              chartData.resources[i - 1][`${yearKey}-actual`];
            chartData.resources[i][`${yearKey}-actual`] = null;
          }
        }
      });
    }
  } else if (type === 'env') {
    for (let i in chartData.resources) {
      Object.keys(chartData.resources[i]).forEach(yearKey => {
        if (yearKey !== 'month') {
          chartData.resources[i][`${yearKey}-actual`] = chartData.resources[i][yearKey].actual;
          chartData.resources[i][`${yearKey}-required`] = chartData.resources[i][yearKey].required;
          chartData.resources[i][`${yearKey}-loss`] = chartData.resources[i][yearKey].loss;
        }
      });
    }
  }

  return chartData;
};

const modifyData = (row, prevRow, yearKey) => {
  if (!isNull(row[yearKey].actual)) {
    row[yearKey].projected = null;
  }
  if (prevRow) {
    if (!isNull(prevRow[yearKey].actual) && isNull(row[yearKey].actual)) {
      row[yearKey].actual = row[yearKey].projected;
    }
  }
  return row;
};
