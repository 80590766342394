import React, { useState, useEffect, useContext } from 'react';

// Context
import { AppContext } from '../../contexts/AppContext';

// Styles
import './body.scss';
import '../grid.scss';

// Components
import BodyContainer from './BodyContainer';
import LossesBody from '../../components/losses/LossesChart';
import InfoIconAndModal from '../../components/informationButton/InfoIconAndModal';

// Assets
import config from '../../abstract/IntroText.json';
import lossModal from '../../components/modal/modalText/LossesModalTitle.json';

// Helpers
import { getCommentaryByWSId } from '../../helpers/ApiHelper';
import { findLastWord } from '../../helpers/Utils';

export default function Losses() {
  const { modal } = lossModal;
  const { waterSource } = useContext(AppContext);
  const [commentary, setCommentary] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const commentary = await getCommentaryByWSId(waterSource.water_source_id);
        if (!Array.isArray(commentary)) {
          const latestCommentary = commentary.data?.resources
            .filter(r => r.type === 'Losses')
            .reverse()[0];
          if (latestCommentary) {
            setCommentary(latestCommentary.body);
          }
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [waterSource]);

  return (
    <>
      <BodyContainer
        pageTitle={
          <div className="losses-pageTop">
            <span>
              {`${config.losses.title} ${findLastWord(waterSource.water_source_name).notLast}`}
              &nbsp;
              <InfoIconAndModal
                modalId="lossesTitleModal"
                modalContent={modal}
                lastWord={
                  <span className={'page-body-title'}>
                    {findLastWord(waterSource.water_source_name).last}
                    &nbsp;
                  </span>
                }
              />
            </span>
          </div>
        }
        pageIntro={commentary}
      >
        <div className="losses-chart-container">
          <LossesBody />
        </div>
      </BodyContainer>
    </>
  );
}
