// Library
import React, { useState, useEffect, useContext } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';

// Context
import { MobileContext } from '../../contexts/MobileContext';

// Style
import './salinityStations.scss';

// Component
import DataSummary from '../../components/dataSummary/DataSummary';
import DataSummaryHeader from '../../components/dataSummary/DataSummaryHeader';
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';
import RiverDataSummaryTable from '../riverData/RiverDataSummaryTable';
import RiverDataSummaryGraph from '../riverData/RiverDataSummaryGraph';
import Loader from '../../components/loader/Loader';

// Helper
import { formatNumberDecimals } from '../../helpers/Utils';
import { getStationsForHunter } from '../../helpers/ApiHelper';
import { getLatestSurfaceWaterDataForMultiSites } from '../../helpers/WaterDataApiHelper';

// Constants
import constants from '../../constants/HydrometricsConstants';
import apiConstants from '../../constants/WaterDataAPIConstants';
const container = 'salinity-stations';

const SalinityStations = ({ refreshTrigger }) => {
  const { isMobile } = useContext(MobileContext);
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [stationId, setStationId] = useState(-1);
  const [activeHydrometric, setActiveHydrometric] = useState();

  const HEADER_PROPS = [
    { name: 'Site No.', key: '' },
    { name: 'Location', key: '' },
    { name: 'Level', key: 'level' },
    { name: 'pH', key: 'pH' },
    { name: 'Discharge', key: 'flow_rate' },
    { name: 'EC', key: 'salinity' },
    { name: 'Water temp.', key: 'temperature' },
    { name: 'Turbidity', key: 'turbidity' },
    { name: 'Status', key: 'hrsts_status' },
    { name: 'Updated', key: '' },
  ];

  const STATUS = {
    0: 'Idle',
    1: 'Discharge',
    2: 'Simulation/Testing',
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      setDetailsLoading(true);
      try {
        const dischargeStations = await getStationsForHunter();
        const stationIds = dischargeStations.map(station => station.id);
        if (isEmpty(dischargeStations)) {
          throw new Error(`API ERROR TO GET DISCHARGE STATION LIST`);
        }
        const stationsHydrometrics = await getLatestSurfaceWaterDataForMultiSites(stationIds);
        const stationData = dischargeStations.map(station => {
          return {
            id: station.id,
            station_id: station.station_id,
            station_name: station.station_name,
            station_display_name: station.station_display_name,
            hydrometric_types: HEADER_PROPS.filter(item => item.key !== '').map(item => item.key),
            resources: stationsHydrometrics?.filter(item => item.siteId === station.station_id)[0],
          };
        });
        setStations(stationData);
        setLoading(false);
      } catch (error) {
        console.error(error);
      } finally {
        setDetailsLoading(false);
      }
    })();
  }, [refreshTrigger]);

  // Mini component
  const SalinityDataSummaryHeader = () => {
    return (
      <div className={`${container}-header`}>
        {HEADER_PROPS.map((item, index) => {
          return (
            <div key={index} className={`priority-${index + 1}`}>
              {`${item.name}${
                item.key &&
                constants.DATA_LABEL_FORMAT[item.key] &&
                constants.DATA_LABEL_FORMAT[item.key].unit
                  ? ` (${constants.DATA_LABEL_FORMAT[item.key].unit.replaceAll(' ', '')})`
                  : ''
              }`}
            </div>
          );
        })}
        <div className={`priority-11`} />
      </div>
    );
  };

  const BodySummaryHeader = station => {
    return (
      <div className={`list-content`}>
        <div className={`priority-1`}>{station.station_id}</div>
        <div className={`priority-2`}>{station.station_name}</div>
        {HEADER_PROPS.map((item, index) => {
          const resource = station.resources?.[item.key] ? station.resources?.[item.key] : null;
          return (
            item.key && (
              <div className={`priority-${index + 1}`} key={index}>
                {detailsLoading ? (
                  <div className="skeleton" />
                ) : resource && resource.value !== null && typeof resource.value !== undefined ? (
                  <DataQualityText qualityCode={resource.qualityCode}>
                    {item.key === 'hrsts_status'
                      ? STATUS[resource.value]
                      : formatNumberDecimals(
                          resource.value,
                          false,
                          false,
                          constants.DATA_LABEL_FORMAT[item.key].decimals,
                        )}
                  </DataQualityText>
                ) : (
                  '-'
                )}
              </div>
            )
          );
        })}{' '}
        <div className={`priority-10`}>
          {station.resources?.date
            ? moment(station.resources?.date, apiConstants.API_DATE_FORMAT).format(
                'HH:mm DD MMM YY',
              )
            : '-'}
        </div>
      </div>
    );
  };

  return (
    <div className={container}>
      <>
        {loading ? (
          <Loader />
        ) : (
          !isEmpty(stations) && <DataSummaryHeader header={SalinityDataSummaryHeader()} />
        )}
        {stations.map((station, stationIndex) => {
          return (
            <div key={station.station_id}>
              <DataSummary
                key={stationIndex}
                id={station.station_id}
                header={BodySummaryHeader(station)}
                onExpand={(state, identifier) => {
                  if (state) {
                    setStationId(identifier);
                  } else {
                    setStationId(-1);
                  }
                }}
                selected={stationId === station.station_id}
              >
                {stationId === station.station_id && (
                  <div className={`${container}-body`}>
                    <RiverDataSummaryTable
                      index={stationIndex}
                      stationData={station}
                      stationType="gauge"
                      dataProps={constants.RIVER_PROPS.gauge}
                      detailsLoading={detailsLoading}
                    />
                    {!isMobile && (
                      <RiverDataSummaryGraph
                        stationData={station}
                        stationType="gauge"
                        dataProps={constants.RIVER_PROPS.gauge}
                        activeHydrometric={activeHydrometric}
                        setActiveHydrometric={id => setActiveHydrometric(id)}
                      />
                    )}
                  </div>
                )}
              </DataSummary>
            </div>
          );
        })}
      </>
    </div>
  );
};

export default SalinityStations;
