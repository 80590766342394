// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// Components
import { Link } from 'react-router-dom';
import DownloadSVG from '../../image/icons/DownloadSVG';
import ChartIcon from './ChartIcon';

// Context
import { DownloadContext } from '../../contexts/DownloadContext';

// Constant
import constants from '../pageComponents/download/new/DataDownloadConstants';

const STATION_INFO = {
  gauge: {
    id: constants.GAUGE,
    hydro: {
      'RIVER LEVEL': 'StreamWaterLevel',
      LEVEL: 'StreamWaterLevel',
      'FLOW RATE': 'FlowRate',
      SPECIAL:
        'DissolvedOxygen0.75MBelowSurface,DissolvedOxygen2.0MBelowSurface,DissolvedOxygen3.0MBelowSurface',
    },
  },
  weir: {
    id: constants.WEIR,
    hydro: { VOLUME: 'TotalStorageVolume', 'RESERVOIR LEVEL': 'StorageWaterLevel' },
  },
  bore: {
    id: constants.BORE,
    hydro: { 'water level': 'GroundwaterLevelAHD,BoreWaterLevelBelowMeasuringPt' },
  },
  dam: {
    id: constants.DAM,
    hydro: {
      VOLUME: 'TotalStorageVolume',
      'RESERVOIR LEVEL': 'StorageWaterLevel',
      'PERCENTAGE FULL': 'ActiveStoragePercentage',
    },
  },
};
export default function ChartToDownloadPageLink({
  siteId,
  hydrometric,
  siteType,
  interval = 'daily',
  label = 'Download More',
  showIcon = true,
  dataType = 'archive',
}) {
  const {
    setInterval,
    setDataType,
    setRequestedSiteId,
    setRequestedHydrometric,
    setSelectedStations,
    setHydrometric,
    setAutoFetch,
    setDownloadType,
    setStartDate,
    setEndDate,
    setStationInfoType,
  } = useContext(DownloadContext);
  const STATION_TYPE = STATION_INFO[siteType].id;
  const HYDRO = STATION_INFO[siteType].hydro;

  const onLinkClick = () => {
    const currentDate = new Date();
    setSelectedStations([]);
    setHydrometric([]);
    setDownloadType(constants.TAB_OPTIONS.find(item => item.id === STATION_TYPE).id);
    setStationInfoType(
      constants.STATION_INFO_TYPE[STATION_TYPE].find(item => item.id === 'Graph & Download').id,
    );
    setRequestedSiteId(siteId);
    setRequestedHydrometric(HYDRO[hydrometric] || hydrometric);
    setStartDate(currentDate.setFullYear(currentDate.getFullYear() - 1));
    setEndDate(new Date());
    setInterval(constants.INTERVAL_SELECTIONS.find(item => item.id === interval));
    setDataType(constants.DATA_TYPES.find(item => item.id === dataType));
    setAutoFetch(true);
  };

  return (
    <Link to={'/download'} onClick={() => onLinkClick()}>
      <ChartIcon>
        {showIcon && <DownloadSVG />}
        {label}
      </ChartIcon>
    </Link>
  );
}

ChartToDownloadPageLink.propTypes = {
  data: PropTypes.array,
  filename: PropTypes.string,
  stationType: PropTypes.string,
  siteType: PropTypes.string,
  label: PropTypes.string,
  dataType: PropTypes.string,
  showIcon: PropTypes.bool,
};
