/* Library */
import React, { useState, useEffect, useLayoutEffect, useContext } from 'react';
import { isEmpty, isNumber } from 'lodash';
import moment from 'moment';

/* Styles */
import './riverDataSummaryGraph.scss';

/* Component */
import DataSummaryGraph from '../dataSummary/DataSummaryGraph';
import Loader from '../../components/loader/Loader';
import RiverDataSummaryTimeDropdown, { dropdownItem } from './RiverDataSummaryTimeDropdown';
import DataQualityText from '../generalComponents/dataQuality/DataQualityText';

/* Helpers */
import {
  decideInterval,
  fetchHydroDataAndForecast,
  getGraphDateArray,
  formatForecastData,
  formatDataForGraph,
  setupHydrometrics,
  arePropsEqual,
} from './Helper';
import { formatNumberDecimals } from '../../helpers/Utils';

/* Context */
import { MobileContext } from '../../contexts/MobileContext';

/* Constants */
import mapFilterConstant from '../../constants/MapFilterConstants';
import hydroConstant from '../../constants/HydrometricsConstants';
import config from '../../configs/featureToggleConfig.json';
const { GAUGE, WEIR, BORE } = mapFilterConstant.MAP_POPUP_ITEMS;
const newGaugeGraph = config['new_gauge_graph'].active;

const customisedUnit = {
  'degrees Centigrade': '°C',
};

const RiverDataSummaryGraph = ({
  stationData,
  stationType,
  dataProps,
  activeHydrometric,
  setActiveHydrometric,
}) => {
  const IS_WEIR = stationType === WEIR.name;
  const IS_GAUGE = stationType === GAUGE.name;
  const IS_BORE = stationType === BORE.name;
  const HAS_FORCAST =
    stationData.has_forecast && (stationData.has_forecast.bom || stationData.has_forecast.carm);
  let variables = IS_WEIR
    ? ['StorageWaterLevel', 'TotalStorageVolume']
    : stationData.water_data_variables
    ? [...stationData.water_data_variables]
    : [];
  const DEFAULT_TIMESPAN = dropdownItem[stationType]?.[0];
  const RAINFALL_STACK_KEYS = [
    { name: 'Cumulative rainfall', color: '#7fa9d2', axisId: 0 },
    { name: 'Rainfall', color: '#0054a6', axisId: 1 },
  ];
  const lableFormat = {
    [GAUGE.name]: hydroConstant.DATA_LABEL_FORMAT,
    [WEIR.name]: hydroConstant.WEIR_DATA_LABEL_FORMAT,
    [BORE.name]: hydroConstant.BORE_DATA_LABEL_FORMAT,
  };
  const { isMedium } = useContext(MobileContext);
  const [resourceActive, setResourceActive] = useState('');
  const [hydroOptions, setHydroOptions] = useState([]);
  const [allHydrometricData, setAllHydrometricData] = useState();
  const [graphData, setGraphData] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [timeSpan, setTimeSpan] = useState(DEFAULT_TIMESPAN);
  const [showToggle, setShowToggle] = useState(
    (IS_WEIR && hydroOptions.length < 3) || !newGaugeGraph,
  );
  const [riverDataProps, setRiverDataProps] = useState(
    stationData.water_data_variables &&
      stationData.water_data_variables.includes('StorageWaterLevel')
      ? dataProps.filter(item => item.waterDataKey !== 'StreamWaterLevel')
      : dataProps.filter(item => item.waterDataKey !== 'StorageWaterLevel'),
  );
  const interval = decideInterval(stationType, timeSpan);
  const selectedHydroOpt = hydroOptions.find(option => option.id === resourceActive);
  const hydroName = selectedHydroOpt ? selectedHydroOpt.hydroName.toLowerCase() : '';
  const isRainfallData = selectedHydroOpt?.waterDataKey === 'Rainfall';
  const stationId = stationData.station_id;
  const isStorageDam = stationId === '206036';
  variables = stationId === '206036' ? variables.concat('ActiveStoragePercentage') : variables;

  useEffect(() => {
    if (isEmpty(hydroOptions)) {
      setupHydrometrics(
        allHydrometricData,
        stationData,
        timeSpan,
        riverDataProps,
        setHydroOptions,
        setResourceActive,
        activeHydrometric,
        IS_WEIR,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allHydrometricData, dataProps]);

  useEffect(() => {
    setShowToggle((IS_WEIR && hydroOptions.length < 3) || !newGaugeGraph);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hydroOptions]);

  useEffect(() => {
    if (stationData.id) {
      if (
        stationData.water_data_variables &&
        stationData.water_data_variables.includes('StorageWaterLevel')
      ) {
        setRiverDataProps(dataProps.filter(item => item.waterDataKey !== 'StreamWaterLevel'));
      } else {
        setRiverDataProps(dataProps.filter(item => item.waterDataKey !== 'StorageWaterLevel'));
      }
    }
  }, [dataProps, stationData]);

  useLayoutEffect(() => {
    if (resourceActive && !isEmpty(hydroOptions)) {
      const formattedHyroName = hydroName.replaceAll(' ', '_');
      if (!allHydrometricData[timeSpan.id] && !IS_WEIR) {
        (async () => {
          const data = await fetchHydroDataAndForecast(
            timeSpan,
            stationData,
            interval,
            variables,
            IS_WEIR,
            setShowSpinner,
            DEFAULT_TIMESPAN,
            HAS_FORCAST,
          );

          const formatedData = data.flow_rate ? formatForecastData(data) : data;
          setAllHydrometricData(prev => ({ ...prev, [timeSpan.id]: formatedData }));
          allHydrometricData[timeSpan.id] &&
            setGraphData(
              formatDataForGraph(allHydrometricData[timeSpan.id][formattedHyroName], IS_WEIR),
            );
        })();
      } else {
        const hydroData = IS_WEIR
          ? allHydrometricData[formattedHyroName]
          : allHydrometricData[timeSpan.id][formattedHyroName];
        setGraphData(formatDataForGraph(hydroData, IS_WEIR));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceActive, allHydrometricData, timeSpan]);

  useEffect(() => {
    (async () => {
      try {
        const data = await fetchHydroDataAndForecast(
          timeSpan,
          stationData,
          interval,
          variables,
          IS_WEIR,
          setShowSpinner,
          DEFAULT_TIMESPAN,
          HAS_FORCAST,
        );
        let formatedData = [];
        if (!IS_WEIR && data.flow_rate) {
          formatedData =
            timeSpan.id === DEFAULT_TIMESPAN.id
              ? formatForecastData(data)
              : formatForecastData(data[timeSpan.id]);
        }
        setAllHydrometricData(
          IS_WEIR ? data : { [timeSpan.id]: data.flow_rate ? formatedData : data },
        );
      } catch (error) {
        setAllHydrometricData();
      }
    })();

    return () => {
      setGraphData({});
      setAllHydrometricData({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Helpers
  const onHydroOptionChange = selectedHydroItem => {
    if (selectedHydroItem !== resourceActive) {
      setGraphData({});
      setResourceActive(selectedHydroItem);
      setActiveHydrometric(selectedHydroItem);
    }
  };

  const onResourceChange = selectedHydroItem => {
    setGraphData({});
    setResourceActive(hydroOptions.find(item => item.displayName === selectedHydroItem).id);
  };

  const onTimeSpanChange = selectedTimeSpan => {
    if (selectedTimeSpan.id !== timeSpan.id) {
      setGraphData({});
      setTimeSpan(selectedTimeSpan);
    }
  };

  const formatTick = v =>
    moment(v).isValid() && !isNumber(v)
      ? moment(v, 'YYYY-MM-DD').format(`${timeSpan.dateFormat || 'MMM-YY'}`)
      : '';

  const formatValue = (value, valueDecimals = null) => {
    const valuesArray = graphData?.content?.map(item =>
      item.value ? item.value : item.forecastValue,
    );
    const difference = valuesArray
      ? Math.abs(Math.max(...valuesArray) - Math.min(...valuesArray))
      : 0;
    const decimals = valueDecimals ? valueDecimals : difference < 1 ? 2 : null;
    const showFraction = valueDecimals || difference < 1;
    let formattedValue = graphData.name === 'volume' ? value / 1000 : value;
    formattedValue = formatNumberDecimals(formattedValue, false, false, decimals, showFraction);
    return formattedValue;
  };

  // Mini component
  const CustomTooltip = ({ active, payload, label }) => {
    const decimals = lableFormat[stationType][hydroName].decimals;
    const tooltipContainer = 'graph-custom-tooltip';
    if (active && payload && payload.length) {
      const currPayload = payload[0];
      if (currPayload.dataKey === 'forecastValue' && currPayload.payload.filler) {
        return null;
      }
      return (
        <div className={tooltipContainer}>
          <p className={`${tooltipContainer}-label`}>{label}</p>
          <p className={`${tooltipContainer}-value`}>
            {payload.length > 1 ? (
              payload.map(payloadItem => {
                return (
                  <>
                    <span>{`${payloadItem.name}: `}</span>
                    <DataQualityText qualityCode={payloadItem.payload?.qualityCode}>
                      {`${formatValue(payloadItem.value, decimals)} ${
                        customisedUnit[graphData.unit] || graphData.unit
                      }`}
                    </DataQualityText>
                    <br />
                  </>
                );
              })
            ) : (
              <>
                <span>{`${currPayload.name.split('(')[0].trim()}: `}</span>
                <DataQualityText qualityCode={currPayload?.payload?.qualityCode}>
                  {`${formatValue(currPayload.value, decimals)} ${
                    customisedUnit[graphData.unit] || graphData.unit
                  }`}
                </DataQualityText>
              </>
            )}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="river-data-summary-graph">
        {showSpinner && (
          <Loader width={isMedium ? '5px' : '10px'} height={isMedium ? '20px' : '40px'} />
        )}
        {!isEmpty(hydroOptions) && (
          <DataSummaryGraph
            chartTitle={
              showToggle ? (
                `PAST 7 DAYS ${HAS_FORCAST && !IS_WEIR ? 'AND 7-DAY FORECAST' : ''}`
              ) : (
                <RiverDataSummaryTimeDropdown
                  timeSpan={timeSpan}
                  onTimeSpanChange={onTimeSpanChange}
                  stationType={stationType}
                />
              )
            }
            chartContent={graphData.content}
            qualities={graphData.qualities}
            toggle={showToggle}
            dropDown={!IS_BORE && !showToggle}
            toggleOptions={
              IS_WEIR
                ? hydroOptions.map(item => item.displayName)
                : !newGaugeGraph && IS_GAUGE
                ? hydroOptions
                    .map(item => item.displayName)
                    .filter(item => ['FLOW RATE', 'RIVER LEVEL'].includes(item))
                : []
            }
            hydroOptions={!showToggle ? hydroOptions : []}
            onHydroSelect={selectedHydroItem => {
              onHydroOptionChange(
                selectedHydroItem,
                resourceActive,
                setGraphData,
                setResourceActive,
                setActiveHydrometric,
              );
            }}
            onToggleClick={onResourceChange}
            activeOption={showToggle ? selectedHydroOpt.displayName : selectedHydroOpt}
            YAxisName={
              graphData?.name
                ? `${
                    selectedHydroOpt.displayName === 'STORAGE LEVEL'
                      ? 'Storage level'
                      : lableFormat[stationType][graphData.name].name
                  } (${
                    IS_BORE ? 'm below ground' : customisedUnit[graphData.unit] || graphData.unit
                  })`
                : ''
            }
            dateFormat="DD MMM"
            labelFormatter={() => null}
            xAxisTicks={getGraphDateArray(graphData)}
            formatTick={formatTick}
            lineDot={true}
            dataStation={stationId}
            allowDownload={true}
            allowHeavyDownload
            allowAlert={true}
            tooltipCustomContent={<CustomTooltip />}
            marginBottom={HAS_FORCAST && !IS_WEIR ? 40 : 30}
            dataType={isStorageDam ? 'dam' : stationType}
            stationInfo={stationData}
            hasDataQuality
            yAxisMinMaxDomain={['dataMin', 'dataMax']}
            yAxisTickFormatter={value => `${formatValue(value)}`}
            graphType={isRainfallData ? 'bar' : 'line'}
            stacked={false}
            stackKeys={isRainfallData ? RAINFALL_STACK_KEYS : []}
            overlay={true}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(RiverDataSummaryGraph, arePropsEqual);
