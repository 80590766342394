// Libraries
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Surface, Symbols } from 'recharts';

// Styles
import './chartLegend.scss';
// Context
import { MobileContext } from '../../contexts/MobileContext.jsx';

// Components
import LegendItemSVG from '../../image/icons/LegendItemSVG';

export default function ChartLegend({ payload, legendType = '', additionalLegend = [] }) {
  const { isMobile } = useContext(MobileContext);

  return (
    <div className="customized-legend">
      {payload.map(({ type, dataKey, color, payload, value }, index) => {
        return (
          <span key={`${dataKey} ${index}`} className="legend-item">
            {legendType === 'square' || type === 'rect' ? (
              <Surface
                width={isMobile ? 15 : 30}
                height={isMobile ? 15 : 30}
                className="legend-surface"
              >
                <Symbols cx={15} cy={15} type="square" size={1000} fill={color} />
              </Surface>
            ) : (
              <LegendItemSVG color={color} />
            )}
            <span className="legend-label">{payload.name || value}</span>
          </span>
        );
      })}
      {additionalLegend.map(
        ({ type, dataKey, color, labelName, strokeDashArray, pathProperty }, index) => {
          return (
            <span key={`${dataKey} ${index}`} className="legend-item">
              {type === 'square' || type === 'rect' ? (
                <Surface
                  width={isMobile ? 15 : 30}
                  height={isMobile ? 15 : 30}
                  className="legend-surface"
                >
                  <Symbols cx={15} cy={15} type="square" size={1000} fill={color} />
                </Surface>
              ) : (
                <LegendItemSVG
                  strokeDasharrayProperty={strokeDashArray}
                  pathProperty={pathProperty}
                  color={color}
                />
              )}
              <span className="legend-label">{labelName}</span>
            </span>
          );
        },
      )}
    </div>
  );
}

ChartLegend.propTypes = {
  payload: PropTypes.array,
  legendType: PropTypes.string,
  additionalLegend: PropTypes.array,
};
