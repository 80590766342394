// Libraries
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { isEmpty, toString } from 'lodash';

// Style
import './storage.scss';

// Context
import { AppContext } from '../../contexts/AppContext';
import { MobileContext } from '../../contexts/MobileContext';

//Components
import DamInfo from './DamInfo';
import StorageChart from './StorageChart';
import Modal from '../modal/Modal';
import DictionaryModalContent from '../modal/DictionaryModalContent';
import DailyUpdatesModalData from '../modal/modalText/DailyUpdatesModalData';
import HourlyUpdatesModalData from '../modal/modalText/HourlyUpdatesModalData';
import DailyUpdates from '../updates/storage/DailyUpdates';

// Helper
import { getLatestResourcesByDamId } from '../../helpers/ApiHelper';
import {
  getFlowData,
  getLatestSurfaceWaterData,
  getSurfaceWaterData,
} from '../../helpers/WaterDataApiHelper';
import { formatStationData } from '../../helpers/ArrayUtils';
import { createMarkup, formatLatestWaterData } from '../../helpers/Utils';
import { getCurrentDate, getDatesByPeriod, formatDate } from '../../helpers/TimeUtils';
import { formatDailyUpdate } from './parserHelper';

// Constants
import constants from '../../constants/Constants';
import apiConstant from '../../constants/WaterDataAPIConstants';
import config from '../../configs/featureToggleConfig.json';
const container = 'storage-body-container';
const getDataFromWaterDataApi = config['water-data-api'].dams.active;

export default function Storage({ commentary }) {
  const { isMobile } = useContext(MobileContext);
  const { waterSource } = useContext(AppContext);
  const activeDams = waterSource.dams;

  const [damResourceData, setDamResourceData] = useState([]);
  const [aggregatedData, setAggregatedData] = useState({});
  const [collapsedHourlyUpdateIds, setCollapsedHourlyUpdateIds] = useState(
    activeDams.map(item => item.station_id),
  );
  const isGreaterSydney = waterSource.water_source_id === constants.GREATER_SYDNEY_WSID;
  const inputDateFormat = getDataFromWaterDataApi ? 'DD-MMM-YYYY HH:mm' : 'YYYY-MM-DD';
  const outputDateFormat = getDataFromWaterDataApi ? 'DD.MM.YYYY hh:mma' : 'DD.MM.YYYY 09:mma';
  const fromDate = getDatesByPeriod(-7, constants.STORAGE_DATE_FORMAT, 'days');
  const toDate = getCurrentDate(constants.STORAGE_DATE_FORMAT);

  // Life cycle
  useEffect(() => {
    (async () => {
      let variables = isGreaterSydney
        ? apiConstant.METRO_DAM_STORAGE_VARIABLES.join(',')
        : apiConstant.DAM_STORAGE_VARIABLES.join(',') + ',TotalStorageVolume';
      const requests = activeDams.map(async dam => {
        const damId = dam.station_id;
        const isBlueMt = damId === 'BlueMountainsTotal';
        const stationName = dam.station_name;
        const unitConversion = { ML: 'GL', 'Percentage (%)': '%' };

        if (
          getDataFromWaterDataApi &&
          !constants.SPECIAL_SITES.map(item => toString(item)).includes(toString(damId))
        ) {
          let waterData = isBlueMt
            ? await getSurfaceWaterData(damId, 'daily', fromDate, toDate, variables)
            : await getLatestSurfaceWaterData(toString(dam.id), 'dam', variables);
          waterData = isBlueMt ? [formatLatestWaterData(waterData, 'dam')] : waterData;
          let damData = waterData[0];

          damData.station_name = stationName;
          ['inflow', 'release', 'volume', 'volume_perc'].map(key => {
            const currItem = damData[key];
            damData[key] = {
              ...currItem,
              date: currItem ? formatDate(currItem.timeStamp, constants.STORAGE_DATE_FORMAT) : '',
              isLatest: currItem && currItem.timeStamp === damData.date,
              value: currItem ? formatValue(currItem, dam.dead_storage) : '-',
              unit: currItem && (unitConversion[currItem.unitOfMeasure] || currItem.unitOfMeasure),
            };
          });
          if (damData.volume_perc.value === '-' && damData.volume.value !== '-') {
            damData.volume_perc.value = ((damData.volume.value * 1000) / dam.full_volume) * 100;
            damData.volume_perc.isLatest = damData.volume.isLatest;
            damData.volume_perc.unit = '%';
          }
          return { ...damData, isWaterDataAPI: true };
        } else {
          let latestData = await getLatestResourcesByDamId(damId);
          latestData.isWaterDataAPI = false;
          latestData = formatStationData(latestData, damId, stationName, inputDateFormat);
          return latestData;
        }
      });

      let damsData = await Promise.all(requests);

      // for Greater Sydney only
      if (isGreaterSydney) {
        calcGreaterSydneySummary(damsData);
        if (getDataFromWaterDataApi) {
          const flowData = await fetchFlowData();
          damsData = damsData.map(formattedItem => {
            const targetFlowData = flowData.find(flowItem => flowItem.id === formattedItem.siteId);
            const inflowValue = targetFlowData?.totalCatchmentInflow
              ? targetFlowData?.totalCatchmentInflow / 1000
              : '-';
            const eflowValue = targetFlowData?.actualReleaseMade
              ? targetFlowData?.actualReleaseMade / 1000
              : '-';

            return {
              ...formattedItem,
              inflow: { value: inflowValue, unit: 'GL' },
              eflow: { value: eflowValue, unit: 'GL' },
            };
          });
        }
      }
      setDamResourceData(damsData);
    })();

    return () => {
      setDamResourceData([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterSource]);

  // Helpers
  const formatValue = (data, fullVolume) => {
    if (data.variableName === 'TotalStorageVolume') {
      return (data.value - fullVolume) / 1000;
    }
    return data.unitOfMeasure === 'ML' ? data.value / 1000 : data.value;
  };

  const calcGreaterSydneySummary = formattedData => {
    let totalFullVolume = 0;
    let totalCurStorage = 0;
    let latestDate;
    formattedData.forEach(formattedItem => {
      // Calc total storage
      const damMetaData = activeDams.find(
        activeDamItem => activeDamItem.station_id === formattedItem.siteId,
      );
      let curStorage = formattedItem.volume ? Number.parseFloat(formattedItem.volume.value) : 0;
      curStorage = Number.isNaN(curStorage) ? 0 : curStorage;
      totalFullVolume += damMetaData && damMetaData.full_volume ? damMetaData.full_volume : 0;
      totalCurStorage += curStorage;

      // Update latest date
      if (latestDate) {
        const tempCurMoment = moment(formattedItem.date, inputDateFormat);
        if (tempCurMoment.isValid() && tempCurMoment.isAfter(latestDate)) {
          latestDate = formattedItem.date;
        }
      } else {
        const tempMoment = moment(formattedItem.date);
        latestDate = tempMoment.isValid() ? formattedItem.date : moment().format(inputDateFormat);
      }

      return { ...formattedItem };
    });
    const dataObj = aggregateDamData(totalFullVolume, totalCurStorage, latestDate);
    let aggregated = [
      {
        label: 'date',
        value: dataObj.date ? moment(dataObj.date, inputDateFormat).format(outputDateFormat) : '-',
      },
    ];
    aggregated = [...aggregated, formatDailyUpdate(dataObj, null, true, true)];
    setAggregatedData(aggregated.flat());
  };

  const fetchFlowData = async () => {
    const requests = activeDams.map(async dam => {
      const damName = dam.station_name;
      const inflowData = await getFlowData(damName, 'daily', fromDate, toDate, 'inflow');
      const eflowData = await getFlowData(damName, 'daily', fromDate, toDate, 'eflow');
      return {
        id: dam.station_id,
        actualReleaseMade: eflowData ? eflowData.pop()?.actualReleaseMade : undefined,
        totalCatchmentInflow: inflowData ? inflowData.pop()?.totalCatchmentInflow : undefined,
      };
    });
    const flowData = await Promise.all(requests);
    return flowData;
  };

  const aggregateDamData = (total, current, date) => {
    const volumePercent = (current / (total / 1000)) * 100;
    return {
      volume: { value: current.toFixed(2), unit: 'GL' },
      volume_perc: { value: volumePercent.toFixed(2), unit: '%' },
      date: date,
    };
  };

  const updateHourlyUpdateIds = newId => {
    if (collapsedHourlyUpdateIds.includes(newId)) {
      setCollapsedHourlyUpdateIds(prevIds => prevIds.filter(id => id !== newId));
    } else {
      setCollapsedHourlyUpdateIds(prevIds => [...prevIds, newId]);
    }
  };

  return (
    <>
      <Modal
        id="dailyUpdatesModal"
        title="Daily Updates"
        body={<DictionaryModalContent data={DailyUpdatesModalData} />}
      />
      <Modal
        id="hourlyUpdatesModal"
        title="Hourly Updates"
        body={<DictionaryModalContent data={HourlyUpdatesModalData} />}
      />
      {isGreaterSydney && !isEmpty(aggregatedData) && (
        <>
          <div className={`${container}-intro`}>
            <div
              dangerouslySetInnerHTML={createMarkup(commentary)}
              className={`${container}-intro-text`}
            />
            <div className={`${container}-intro-tile`}>
              <DailyUpdates
                isForWaterSource
                data={aggregatedData.filter(i => i.label !== 'date')}
                timestamp={`Updated: ${aggregatedData[0].value}`}
              />
            </div>
          </div>
        </>
      )}
      {waterSource && (
        <div className="dam-info-container">
          {activeDams.map((dam, i) => {
            const damResource = damResourceData.find(i => dam.station_id === i.station_id);
            return (
              <DamInfo
                key={i}
                damResource={damResource}
                station={dam}
                collapsedHourlyUpdateIds={collapsedHourlyUpdateIds}
                updateHourlyUpdateIds={updateHourlyUpdateIds}
                inputDateFormat={inputDateFormat}
                outputDateFormat={
                  damResource?.isWaterDataAPI && getDataFromWaterDataApi
                    ? 'DD.MM.YYYY hh:mma'
                    : 'DD.MM.YYYY 09:mma'
                }
              />
            );
          })}
        </div>
      )}
      <div className="storage-chart-container">
        {!isMobile && (
          <div className="storage-chart-container-title ">HISTORICAL DAM STORAGE DATA</div>
        )}
        <StorageChart curWaterSource={waterSource} />
      </div>
    </>
  );
}

Storage.propTypes = {
  commentary: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
};
