// Library
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer } from 'recharts';

// Context
import { MobileContext } from '../../contexts/MobileContext.jsx';

// Component
import EmptyChartMsg from './EmptyChartMsg.jsx';
import Loader from '../loader/Loader';

const ChartResponsiveContainer = ({
  children,
  customHeight = { desktop: 550, mobile: 400 },
  customWidth = { desktop: '100%', tablet: '100%', mobile: '100%' },
  isEmptyChart = false,
  isLoading = false,
}) => {
  const { isMobile, isMedium } = useContext(MobileContext);

  return (
    <div
      style={{
        width: isMedium ? customWidth.tablet : isMobile ? customWidth.mobile : customWidth.desktop,
        height: isMobile ? customHeight.mobile : customHeight.desktop,
        position: 'relative',
      }}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <ResponsiveContainer
          width={
            isMedium ? customWidth.tablet : isMobile ? customWidth.mobile : customWidth.desktop
          }
          minWidth="200px"
          height={isMobile ? customHeight.mobile : customHeight.desktop}
        >
          {isEmptyChart ? <EmptyChartMsg /> : children}
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ChartResponsiveContainer;

ChartResponsiveContainer.propTypes = {
  children: PropTypes.any,
  customHeight: PropTypes.object,
  customWidth: PropTypes.object,
};
