// Libraries
import React from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';

// Styles
import './mandatoryConditions.scss';

// Helpers
import { groupConditionsByCategory } from '../../helpers/ArrayUtils';
import { createMarkup } from '../../helpers/Utils';

// Assets
import config from '../../abstract/IntroText.json';

// Components
import AppModal from '../modal/Modal';
import DictionaryModalContent from '../modal/DictionaryModalContent';
import ConditionsModalData from '../modal/modalText/ConditionsModalData';
import InfoButton from '../informationButton/InformationButton';
import ExpandBox from '../generalComponents/expandBox/ExpandBox';

export default function MandatoryConditions(props) {
  // Mini Component
  const ruleHeader = category => {
    return (
      <>
        <div className="conditions-inner-body-category-name">{category[0].toUpperCase()}</div>
      </>
    );
  };

  const ruleContent = (index, category) => {
    return (
      <div key={index}>
        {Object.entries(category[1]).map((subClass, indexClass) => (
          <div key={indexClass}>
            <div className="conditions-inner-body-class" key={indexClass}>
              {subClass[0]}
            </div>
            {Object.values(subClass[1]).map((subClassItem, indexSubClass) => (
              <div className="conditions-inner-body-class-content" key={indexSubClass}>
                <div
                  className="conditions-inner-body-class-content-condition"
                  key={subClassItem.number}
                >
                  {subClassItem.number}&nbsp;&nbsp;&nbsp;&nbsp;{subClassItem.text}
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className="conditions">
      <div className="row conditions-title">
        <span>Conditions</span>
        <InfoButton targetDataId="#conditionsModalCenter" />
      </div>
      {
        <AppModal
          id="conditionsModalCenter"
          title="Understanding water rules"
          body={<DictionaryModalContent data={ConditionsModalData} />}
        />
      }
      {!isEmpty(props.conditionsData) && (
        <div className="conditions-body">
          <div
            dangerouslySetInnerHTML={createMarkup(config.conditions.intro)}
            className="conditions-body-intro"
          />
          <div className="conditions-inner-body">
            {Object.entries(groupConditionsByCategory(props.conditionsData)).map(
              (category, index) => (
                <ExpandBox
                  key={index}
                  clickableSurface
                  customHeaderContent={ruleHeader(category)}
                  customHeaderContainerStyle={`conditions-inner-body-licence`}
                  customArrowColor={`conditions-inner-body-licence-arrow`}
                >
                  {ruleContent(index, category)}
                </ExpandBox>
              ),
            )}
          </div>
        </div>
      )}
    </div>
  );
}

MandatoryConditions.propTypes = {
  conditionsData: PropTypes.array,
};
