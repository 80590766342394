/* Library */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

/* Style */
import './updateTabs.scss';

/* Context */
import { MobileContext } from '../../contexts/MobileContext.jsx';

/* Components */
import ExternalLinkModal from '../../components/modal/ExternalLinkModal';

/* Helpers */
import { replaceWaterSourceLiteral } from '../../helpers/Utils';

// Assets
import externalLink from '../../image/icons/externalLink.png';

export default function UpdateTabs({ latestUpdates, setSelectedTabType }) {
  const { isMobile } = useContext(MobileContext);
  const [selectedTab, setSelectedTab] = useState();
  const [externalSiteLink, setExternalSiteLink] = useState('');

  const onExternalSiteClick = link => {
    setExternalSiteLink(link);
  };

  useEffect(() => {
    if (selectedTab > -1 && !isEmpty(latestUpdates)) {
      setSelectedTabType(latestUpdates[selectedTab].type.toLowerCase());
    }
  });

  useEffect(() => {
    setSelectedTabMobileDesktop();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  // Set the selectedTab index to -1 in case we went from desktop to mobile version
  // or set the selectedTab index to 0 in case we went from mobile to desktop
  const setSelectedTabMobileDesktop = () => {
    if (isMobile) {
      setSelectedTab(-1);
    } else {
      setSelectedTab(0);
    }
  };

  return (
    <>
      {!isEmpty(latestUpdates) &&
        latestUpdates.map((update, index) => (
          <div
            key={index}
            className={`update-tab${selectedTab === index ? '-selected' : ''}`}
            onClick={() => {
              !isMobile && setSelectedTab(index);
            }}
          >
            <div className={`update-tab-content${selectedTab === index ? '-selected' : ''}`}>
              <div className="update-title">{update.title}</div>
              <div className="update-heading">
                <div>{`${update.type} ${
                  update.water_source_name
                    ? ' - ' + replaceWaterSourceLiteral(update.water_source_name)
                    : ''
                }`}</div>
              </div>
              <div className="update-tab-body">
                <div className="update-tab-body-content">
                  <div className="update-tab-body-content-text">
                    {update.body.replace(/<\/?[^>]+(>|$)/g, '')}
                  </div>
                  <div className="update-tab-body-content-readmore">
                    <Link to={`/nsw-update#${index}`}> read more</Link>
                  </div>
                </div>

                {update.url && (
                  <a
                    className={`update-tab-link-content update-tab-link${
                      selectedTab === index ? '-selected' : ''
                    }`}
                    data-toggle={isMobile ? 'modal' : ''}
                    data-target={isMobile ? '#externalLinkModalUpdates' : ''}
                    onClick={() => onExternalSiteClick(update.url)}
                    href={update.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View update
                    <img
                      src={externalLink}
                      className="external-link-icon"
                      alt="Link icon linking to the source of the rules"
                    />
                  </a>
                )}
              </div>
              <ExternalLinkModal link={externalSiteLink} modalId="externalLinkModalUpdates" />
            </div>
          </div>
        ))}
    </>
  );
}

UpdateTabs.propTypes = {
  latestUpdates: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.string,
      date: PropTypes.string,
      id: PropTypes.number,
      title: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
      water_source_id: PropTypes.number,
      water_source_name: PropTypes.string,
    }),
  ),
  setSelectedTabType: PropTypes.func,
};
